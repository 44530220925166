import React from 'react';
import CircularWithValueLabel from '../circular-progress-bar/CircularProgressBar';
import Slider from 'react-slick';

export default function PdpSlider({ attributes }) {
  const ProdDetails = [
    {
      id: 1,
      title: 'waterproof',
      description: 'splash away! no-budge look',
      count: 0,
      imgUri: require('../../assets/check_pdp.png')
    },
    {
      id: 2,
      title: 'moisturising',
      description: 'bye-bye dryness!',
      count: 0,
      imgUri: require('../../assets/check_pdp.png')
    },
    {
      id: 3,
      title: 'other smudg members love this!',
      description: 'best option for your skin type & goals',
      count: 17,
      imgUri: require('../../assets/check_pdp.png')
    },
    {
      id: 4,
      title: 'acid-based products',
      description: 'too drying based on your skin type goals',
      count: 0,
      imgUri: require('../../assets/check_pdp.png')
    }
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    nextArrow: null,
    prevArrow: null,
    adaptiveHeight: false
    // centerPadding: 210,
  };

  function details(item) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '90%',
          marginBottom: 4
        }}>
        <div
          style={{
            width: 40
          }}>
          <div
            style={{
              display: 'flex',
              backgroundColor: 'rgba(243, 250, 171, 1)',
              width: 40,
              height: 40,
              borderRadius: 40,
              borderColor: 'rgba(33, 33, 33, 1)',
              borderWidth: 1,
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            {item.count ? (
              <div style={{ fontSize: 12, fontFamily: 'PlusJakartaSans-Bold' }}>
                {item.count}
              </div>
            ) : (
              <img
                alt='Check mark'
                src={require('../../assets/check_pdp.png')}
                style={{ width: 20, height: 19 }}
              />
            )}
          </div>
        </div>
        <div
          style={{
            width: '80%',
            paddingLeft: 16
          }}>
          <div
            style={{
              fontFamily: 'PlusJakartaSans-SemiBold',
              fontSize: 13,
              color: '#000'
            }}>
            {item.title}{' '}
          </div>
          <div
            style={{
              fontFamily: 'PlusJakartaSans-Regular',
              fontSize: 13,
              color: 'rgba(0, 0, 0, 0.6)'
            }}>
            {item.description}{' '}
          </div>
        </div>
      </div>
    );
  }
  return (
    <Slider {...settings}>
      <div>
        <div
          style={{
            width: '255.513px',
            height: '234.754px',
            border: '1.5px solid black',
            borderRadius: 10,
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            flexDirection: 'row',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: 30
          }}>
          <CircularWithValueLabel />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: 4,
                marginBottom: 4
              }}>
              <div
                style={{
                  width: 10,
                  height: 10,
                  borderRadius: 25,
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  marginRight: 8,
                  backgroundColor: '#E57373'
                }}
              />
              <div
                style={{
                  marginBottom: 2,
                  fontFamily: 'PlusJakartaSans-Regular',
                  fontSize: 14,
                  fontWeight: '500'
                }}>
                concerns
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: 4,
                marginBottom: 4
              }}>
              <div
                style={{
                  width: 10,
                  height: 10,
                  borderRadius: 25,
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  marginRight: 8,
                  backgroundColor: '#BA68C8'
                }}
              />
              <div
                style={{
                  marginBottom: 2,
                  fontFamily: 'PlusJakartaSans-Regular',
                  fontSize: 14,
                  fontWeight: '500'
                }}>
                skin type
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: 4,
                marginBottom: 4
              }}>
              <div
                style={{
                  width: 10,
                  height: 10,
                  borderRadius: 25,
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  marginRight: 8,
                  backgroundColor: '#81C784'
                }}
              />
              <div
                style={{
                  marginBottom: 2,
                  fontFamily: 'PlusJakartaSans-Regular',
                  fontSize: 14,
                  fontWeight: '500'
                }}>
                ingredients
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: 4,
                marginBottom: 4
              }}>
              <div
                style={{
                  width: 10,
                  height: 10,
                  borderRadius: 25,
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  marginRight: 8,
                  backgroundColor: '#FFD54F'
                }}
              />
              <div
                style={{
                  marginBottom: 2,
                  fontFamily: 'PlusJakartaSans-Regular',
                  fontSize: 14,
                  fontWeight: '500'
                }}>
                preferences
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div
          style={{
            width: '255.513px',
            height: '234.754px',
            border: '1.5px solid black',
            borderRadius: 10,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: 30
          }}>
          {attributes && attributes.length > 0
            ? attributes.map((item) => {
                return details(item);
              })
            : ProdDetails.map((item) => {
                return details(item);
              })}
        </div>
      </div>
    </Slider>
  );
}
