import React from "react";
import PropTypes from "prop-types";
import { Checkbox } from "@mui/material";

export default function McqOption({
  borderColor,
  checkBoxColor,
  value,
  label,
  image,
  isSelected,
  onSelected,
}) {
  const style = {
    display: "flex",
    width: "110.357px",
    height: "103.006px",
    border: `2px solid ${isSelected ? checkBoxColor : borderColor}`,
    borderRadius: 2,
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    flexDirection: "column",
    userSelect: "none",
  };

  const handleClick = () => {
    onSelected(!isSelected);
  };

  return (
    <div
      style={{ ...style, cursor: "pointer" }}
      onClick={() => handleClick()}
      role="button"
      tabIndex={0}
    >
      {image}
      <Checkbox
        checked={isSelected}
        onChange={() => handleClick()}
        sx={{
          color: isSelected ? checkBoxColor : borderColor,
          "&.Mui-checked": {
            color: checkBoxColor,
          },
          position: "absolute",
          top: "5.69px",
          right: "6.23px",
          "& .MuiSvgIcon-root": { fontSize: "15.008px" },
          width: 0,
          height: 0,
        }}
      />
      <div
        style={{
          fontSize: "11px",
          fontWeight: "700",
          color: "#000",
          position: "absolute",
          bottom: "13.66px",
          fontFamily: "PlusJakartaSans-Bold",
        }}
      >
        {label}
      </div>
    </div>
  );
}

McqOption.propTypes = {
  borderColor: PropTypes.string,
  checkBoxColor: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  image: PropTypes.element,
  isSelected: PropTypes.bool,
  onSelected: PropTypes.func,
};

McqOption.defaultProps = {
  borderColor: "#0F0F0E",
  checkBoxColor: "#000",
  value: "dryness",
  label: "Dryness",
  image: (
    <img
      alt="Dryness"
      src={require("../../assets/dryness.png")}
      style={{ width: '32px', height: '33.6px' }}
    />
  ),
  isSelected: false,
  onSelected: (value) => console.log(value),
};
