import React from 'react';
import SmudgLogoComponent from '../../../../components/smudg-logo/SmudgLogo';
import TabStepper from '../../../../components/tab-stepper/TabStepper';
import CircleMcqQuestion from '../../../../components/circle-mcq-question/CircleMcqQuestion';
import { useDispatch, useSelector } from 'react-redux';
import { selectl1, setl1 } from '../../../../slices/QuizQuestionReducer';
import { useNavigate } from 'react-router';
import {
  eyeIcon,
  lipsIcon,
  lipsQuizQ1,
  sparkleIcon
} from '../../../../assets';
import CloseButton from '../../../../components/close-button/CloseButton';
import BrandLogo from '../../../../components/brand-logo/BrandLogo';
import { BrandLogoImage, borderColor, buttonColor } from '../../Config';

export default function FirstLipsQuestion() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selected, setSelected] = React.useState('');

  const questionProps = {
    headQuestion: 'your lip shape',
    value: useSelector(selectl1)
      .map((el) => el.l1)
      .toString(),
    image: (
      <img
        alt='Lips Quiz Question 1'
        src={lipsQuizQ1}
        style={{ width: "109px", height: "109px", borderRadius: "50%" }}
      />
    ),
    subQuestion:
      'thin, medium or full? choose  the shape closest to your natural lips',
    questionArray: [
      {
        id: 1,
        name: 'thin and beautiful',
        value: 'thin'
      },
      {
        id: 2,
        name: 'medium and balanced',
        value: 'normal'
      },
      {
        id: 3,
        name: 'full and pouty',
        value: 'plump'
      }
    ],
    onSelected: (item) => {
      setSelected(item);
    }
  };

  const tabProperties = {
    tabs: [
      {
        name: 'face',
        imageSrc: sparkleIcon,
        text: '6 of 6'
      },
      {
        name: 'eyes',
        imageSrc: eyeIcon,
        text: '3 of 3'
      },
      {
        name: 'lips',
        imageSrc: lipsIcon,
        text: '1 of 2'
      }
    ],
    activeTab: 'lips'
  };

  const handleBackClick = () => {
    navigate('/eyes-three');
  };

  const handleNextClick = () => {
    if (selected !== '') {
      dispatch(setl1({ l1: selected }));
      navigate('/lips-two');
    }
  };

  return (
    <div>
      <div style={{ width: '100%' }}>
        <div
          style={{
            marginTop: "15px",
            marginRight: "27px",
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end'
          }}>
          <CloseButton />
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            marginTop: "41px",
            justifyContent: 'space-evenly'
          }}>
          <div
            style={{
              width: "100%",
              alignItems: "flex-start",
              justifyContent: "start",
              fontSize: 20,
              fontFamily: "PlusJakartaSans-Bold",
            }}
          >
            <div style={{ marginLeft: "57px" }}>Lips Profile</div>
          </div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <TabStepper {...tabProperties} />
          </div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-end'
            }}>
           <BrandLogo styles={{marginRight: '27px'}} image={BrandLogoImage} />
          </div>
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'flex-start',
            marginTop: "21.69px",
          }}>
          <CircleMcqQuestion
            {...questionProps}
            onBackButtonClick={() => handleBackClick()}
            onNextButtonClick={() => handleNextClick()}
            buttonColor={buttonColor}
            borderColor={borderColor}
          />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}>
        <SmudgLogoComponent
          variant={'dark'}
          onClick={() => console.log('click')}
          styles={{
            width: "119px",
            height: "14.7px",
            position: "absolute",
            bottom: 13,
          }}
        />
      </div>
    </div>
  );
}
