import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";

export default function TextFieldAnswer({
  multiLine,
  value,
  placeholder,
  onBlur,
}) {
  const [text, setText] = React.useState(value);

  React.useEffect(() => {
    setText(value);
  }, [value])

  return (
    <Box
      sx={{
        width: 500,
        maxWidth: "100%",
      }}
    >
      <TextField
        id="standard-basic"
        placeholder={placeholder}
        variant={"outlined"}
        multiline={multiLine}
        sx={{
          ".MuiOutlinedInput-root": {
            borderRadius:34,
            background: "rgba(106, 106, 106, 0.15)",
            color: "rgba(0, 0, 0, 0.47)",
            fontFamily:"PlusJakartaSans-SemiBold",
            fontWeight:"600",
            fontSize:18,
          },
          "& input":{
            textAlign:"center"
          }
        }}
        style={{
          ...style.theme,
        }}
        value={text}
        onChange={(event) => {
          setText(event.target.value);
          onBlur(event.target.value)
        }}
        fullWidth
      />
    </Box>
  );
}

let style = {
  theme: {
    color: "#6F7E8C",
  },
};

TextFieldAnswer.defaultProps = {
  multiLine: false,
  value: "",
  placeholder: "Type your answer here...",
  onBlur: (val) => {
    console.log("Value: ", val);
  },
};

TextFieldAnswer.propTypes={
  multiLine:PropTypes.bool,
  value:PropTypes.string,
  placeholder:PropTypes.string,
  onBlur:PropTypes.func,
}
