import React from "react";
import SmudgLogoComponent from "../../../components/smudg-logo/SmudgLogo";
import BrandLogo from "../../../components/brand-logo/BrandLogo";
import TextQuestion from "../../../components/text-question/TextQuestion";
import { useDispatch, useSelector } from "react-redux";
import { selectFirst, setFirst } from "../../../slices/QuizQuestionReducer";
import { useNavigate } from "react-router";
import CloseButton from "../../../components/close-button/CloseButton";
import { BrandLogoImage, buttonColor } from "../Config";
import TextQuestionComponent from "../../../components/text-question-component/TextQuestionComponent";

export default function FirstQuizQuestion() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const question = () => {
    return (
      <div>
        <div
          style={{
            color: "rgba(0, 0, 0, 1)",
            fontFamily: "PlusJakartaSans-Medium",
            fontSize: 16,
            textTransform: "lowercase",
          }}
        >
          Let's begin by identifying your skin type and needs.
        </div>
        <div
          style={{
            color: "rgba(0, 0, 0, 1)",
            fontFamily: "PlusJakartaSans-Medium",
            fontSize: 15,
            marginTop: "17px",
            textTransform: "lowercase",
          }}
        >
          To start, can we have your name?
        </div>
      </div>
    );
  };

  const val = useSelector(selectFirst);
  const value = Object.values(val)[0];

  const handleClick = (val) => {
    if (val !== "") {
      navigate("/second");
      dispatch(setFirst(val));
    }
  };

  return (
    <div>
      <div style={{ width: "100%" }}>
        <div
          style={{
            marginTop: "15px",
            marginRight: "27px",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <CloseButton />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            marginTop: "41px",
            alignItems: "flex-end",
          }}
        >
          <BrandLogo styles={{ marginRight: "27px" }} image={BrandLogoImage} />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: 51,
            flexDirection: "row",
          }}
        >
          <div
            style={{
              color: "rgba(15, 15, 14, 0.70)",
              fontFamily: "PlusJakartaSans-Bold",
              fontSize: 28,
              marginRight: 10,
              marginTop: -7,
            }}
          >
            Q1.
          </div>
          <TextQuestionComponent
            value={value}
            children={question()}
            onNextButtonClicked={(val) => handleClick(val)}
            buttonColor={buttonColor}
            buttonStyles={{ display: "flex", justifyContent: "center" }}
            textField={true}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <SmudgLogoComponent
          variant={"dark"}
          onClick={() => console.log("click")}
          styles={{
            width: "118px",
            height: "14.7px",
            position: "absolute",
            bottom: 13,
          }}
        />
      </div>
    </div>
  );
}
