import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  first:"",
  second:[],
  third:[],
  f1: [],
  f2: [],
  f3: [],
  f4: [],
  f5: [],
  f6: [],
  l1: [],
  l2: [],
  e1: [],
  e2: [],
  e3: [],
};

export const quizSlice = createSlice({
  name: "quiz",
  initialState,
  reducers: {
    setFirst: (state, action) => {
      state.first = [action.payload];
    },
    setSecond: (state, action) => {
      state.second = [action.payload];
    },
    setThird: (state, action) => {
      state.third = [action.payload];
    },
    setf1: (state, action) => {
      state.f1 = [action.payload];
    },
    setf2: (state, action) => {
      state.f2 = [action.payload];
    },
    setf3: (state, action) => {
      state.f3 = [action.payload];
    },
    setf4: (state, action) => {
      state.f4 = [action.payload];
    },
    setf5: (state, action) => {
      state.f5 = [action.payload];
    },
    setf6: (state, action) => {
      state.f6 = [action.payload];
    },
    setl1: (state, action) => {
      state.l1 = [action.payload];
    },
    setl2: (state, action) => {
      state.l2 = [action.payload];
    },
    sete1: (state, action) => {
      state.e1 = [action.payload];
    },
    sete2: (state, action) => {
      state.e2 = [action.payload];
    },
    sete3: (state, action) => {
      state.e3 = [action.payload];
    },
  },
});

export const {
  setFirst,
  setSecond,
  setThird,
  setf1,
  setf2,
  setf3,
  setf4,
  setf5,
  setf6,
  setl1,
  setl2,
  sete1,
  sete2,
  sete3,
} = quizSlice.actions;

export const selectFirst = (state) => state.quiz.first;
export const selectSecond = (state) => state.quiz.second;
export const selectThird = (state) => state.quiz.third;
export const selectf1 = (state) => state.quiz.f1;
export const selectf2 = (state) => state.quiz.f2;
export const selectf3 = (state) => state.quiz.f3;
export const selectf4 = (state) => state.quiz.f4;
export const selectf5 = (state) => state.quiz.f5;
export const selectf6 = (state) => state.quiz.f6;
export const selectl1 = (state) => state.quiz.l1;
export const selectl2 = (state) => state.quiz.l2;
export const selecte1 = (state) => state.quiz.e1;
export const selecte2 = (state) => state.quiz.e2;
export const selecte3 = (state) => state.quiz.e3;

export default quizSlice.reducer;
