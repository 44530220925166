export const backArrow = require("./back_arrow.png");
export const chappedLips = require("./chapped_lips.png");
export const checkPDP = require("./check_pdp.png");
export const onboardingImage = require("./soul-sattva-onboarding.png");

export const soulSattvaLogo = require("./Soul_Satva_logo.png");
export const sparkleIcon = require("./quiz_icons/sparkle.png");
export const eyeIcon = require("./quiz_icons/eye.png");
export const lipsIcon = require("./quiz_icons/lips.png");

export const dryness = require("./dryness.png");
export const pores = require("./pores.png");
export const dullness = require("./dullness.png");
export const linesWrinkles = require("./wrinkles.png");
export const oilShine = require("./oil_and_shine.png");
export const acne = require("./acne.png");
export const darkCircles = require("./dark_circles.png");
export const darkSpots = require("./dark_spots.png");
export const puffiness = require("./puffiness.png");

export const lightSkinTone = require("./light.png");
export const lightMediumSkinTone = require("./medium_light.png");
export const mediumSkinTone = require("./medium.png");
export const mediumDarkSkinTone = require("./medium_deep.png");
export const darkSkinTone = require("./deep.png");
export const extremelyDarkSkinTone = require("./extremely_dark.png");

export const faceQuizQ1 = require("./quiz_questions/face_quiz_question_1.png");
export const faceQuizQ2 = require("./quiz_questions/face_question_2.png");
export const faceQuizQ3 = require("./quiz_questions/face_question_3.png");
export const faceQuizQ4 = require("./quiz_questions/face_question_4.png");
export const faceQuizQ5 = require("./quiz_questions/face_quiz_question_5.png");
export const faceQuizQ6 = require("./quiz_questions/face_question_6.png");

export const eyesQuizQ1 = require("./quiz_questions/eyes_question_1.png");
export const eyesQuizQ2 = require("./quiz_questions/eyes_question_2.png");
export const eyesQuizQ3 = require("./quiz_questions/eyes_question_3.png");

export const lipsQuizQ1 = require("./quiz_questions/lips_question_1.png");
export const lipsQuizQ2 = require("./quiz_questions/lips_question_2.png");

export const quizResultOily = require("./quiz_results/oily.png");
export const quizResultDry = require("./quiz_results/dry.png");
export const quizResultCombination = require("./quiz_results/combination.png");
export const skinSummaryImage = require("./skin-summary.png");

export const perfectMatch = require("./perfect_match.png");
export const productPDP = require("./product_pdp.png");
export const bluePondLogo = require("./blue_pond_logo.png");
export const congratulations = require("./congratulations.png");
export const preQuizResultsGIF = require("./quiz-results-gif.gif");
export const skinSummary=require("./SkinSummaryImage.png")

const images = {
  backArrow,
  chappedLips,
  checkPDP,
  onboardingImage,
  soulSattvaLogo,
  dryness,
  pores,
  dullness,
  linesWrinkles,
  oilShine,
  lightSkinTone,
  lightMediumSkinTone,
  mediumSkinTone,
  mediumDarkSkinTone,
  darkSkinTone,
  extremelyDarkSkinTone,
  sparkleIcon,
  eyeIcon,
  lipsIcon,
  faceQuizQ1,
  faceQuizQ2,
  faceQuizQ3,
  faceQuizQ4,
  faceQuizQ5,
  faceQuizQ6,
  eyesQuizQ1,
  eyesQuizQ2,
  eyesQuizQ3,
  lipsQuizQ1,
  lipsQuizQ2,
  quizResultOily,
  quizResultDry,
  quizResultCombination,
  perfectMatch,
  productPDP,
  bluePondLogo,
  congratulations,
  skinSummary
};

export default images;
