import React from "react";
import { useStyles } from "./ProductCardSyles";

const ProductCard = (params) => {
  const styles = useStyles();
  const cardData = params.data;
  const brand = cardData?.brand;
  const prod_name = cardData?.prod_name;
  const category = cardData?.category;
  const imageUrl = cardData?.product_image_url;
  const showLabel = false;

  const handleClick = () => {
    params.onClick();
  };

  return (
    <div
      className={styles.card}
      onClick={() => handleClick()}
      style={{ cursor: "pointer" }}
      role="button"
      tabIndex={0}
    >
      <div className={styles.upperCard}>
        <div className={styles.match}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              alt="Super Match"
              src={require("../../assets/superMatch.png")}
              style={{
                width: "10px",
                height: "11px",
              }}
            />
            <span
              style={{
                color: "white",
                fontFamily: "PlusJakartaSans-Bold",
                fontSize: "8px",
                marginLeft: 4,
              }}
            >
              super match
            </span>
          </div>
        </div>
        <img
          alt="Product"
          src={imageUrl || require("../../assets/product.png")}
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      </div>
      <div className={styles.prodNameStyle}>
        <div className={styles.brandName}>
          {brand?.name?.length > 6 && showLabel
            ? `${brand?.name?.substring(0, 3)?.toLowerCase()}...`
            : brand?.name?.length > 12
            ? `${brand?.name?.substring(0, 12)?.toLowerCase()}...`
            : brand?.name?.toLowerCase()}
        </div>
      </div>
      <div className={styles.prodName}>{prod_name?.toLowerCase()}</div>
      <div className={styles.prodCategory}>
        {category
          ? category?.name?.match(/^#\w+$/)
            ? category?.name
            : "#" + category?.name
          : ""}
      </div>
      <div className={styles.buttonDiv}>
        <div
          className={styles.button}
          onClick={() => handleClick()}
          role="button"
          tabIndex={0}
        >
          <div className={styles.buttonText}>View</div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
