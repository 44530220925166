import React, { useEffect, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import PropTypes from "prop-types";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./textCarousel.css";

export default function TextCarousel({ textData,onChangeIndex,currentIndex }) {
  const sliderRef = React.useRef(null);
  const [slideIndex, setSlideIndex] = useState(0);

  const NextButton = () => {
    return (
      <ArrowForwardIosIcon
        sx={{ color: "#000", fontSize: 18 }}
        onClick={() => sliderRef?.current?.slickNext()}
      />
    );
  };

  const PrevButton = () => {
    return (
      <ArrowBackIosNewIcon
        sx={{ color: "#000", fontSize: 18 }}
        onClick={() => sliderRef?.current?.slickPrev()}
      />
    );
  };

  const makeTextCard = () => {
    return textData.map((item, key) => {
      return (<div key={key}><span style={{color: "#000",
      display:"flex",
      textAlign: "center",
        fontFamily: "PlusJakartaSans-Medium",
        fontSize: "13px",
        fontWeight: "500"}}>{item}</span></div>);
    });
  };

  useEffect(()=>{
    setSlideIndex(currentIndex)
    sliderRef.current.slickGoTo(currentIndex)
  },[currentIndex])

  const handleChange=(index)=>{
    setSlideIndex(index)
    onChangeIndex(index)
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: null,
    prevArrow: null,
    adaptiveHeight: true,
    beforeChange: (current, next) => handleChange(next)
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <PrevButton/>
      <Slider ref={sliderRef} style={{ width: 427 }} {...settings}>
        {makeTextCard()}
      </Slider>
      <NextButton/>
    </div>
  );
}

TextCarousel.defaultProps = {
  textData: [
    "'O' Oily skin excels in sebum production, offering natural defense against sun, pollution, and aging. Embrace it with products that balance hydration while controlling excess oiliness.",
    "'O' Oily skin excels in sebum production, offering natural defense against sun, pollution, and aging. Embrace it with products that balance hydration while controlling excess oiliness.",
    "'O' Oily skin excels in sebum production, offering natural defense against sun, pollution, and aging. Embrace it with products that balance hydration while controlling excess oiliness.",
    "'O' Oily skin excels in sebum production, offering natural defense against sun, pollution, and aging. Embrace it with products that balance hydration while controlling excess oiliness.",
  ],
  onChangeIndex:(i)=>console.log(i)
};

TextCarousel.propTypes = {
  textData: PropTypes.array,
  onChangeIndex:PropTypes.func
};
