import React from 'react';
import IconButton from '../../../components/icon-button/IconButton';
import Button from '../../../components/button/Button';
import SmudgLogoComponent from '../../../components/smudg-logo/SmudgLogo';
import { useNavigate } from 'react-router';
import PdpSlider from '../../../components/pdp-slider/PdpSlider';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useLocation } from 'react-router-dom';
import { backArrow, perfectMatch, productPDP } from '../../../assets';
import CloseButton from '../../../components/close-button/CloseButton';
import CloseIcon from '@mui/icons-material/Close';
import { backgroundColor, brandNameColor, buttonColor } from '../Config';

export default function ProdDetails() {
  const navigate = useNavigate();
  let location = useLocation();
  let product = location.state.item;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <div
        style={{
          width: '100%',
          backgroundColor: backgroundColor,
          height: '80px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
        <IconButton
          icon={
            <img
              alt='Back Arrow'
              src={backArrow}
              style={{
                width: '18px',
                height: '16.364px',
                marginLeft: '39px',
                color: '#fff'
              }}
            />
          }
          onClick={() => navigate('/product-recco')}
        />
        <div
          style={{
            marginRight: '43px',
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end'
          }}>
          <CloseButton icon={<CloseIcon
     style={{fontSize:23,color:"#fff"}}
    />}/>
        </div>
      </div>
      <Grid container spacing={2} style={{ height: '80vh', marginTop: 0 }}>
        <Grid item xs={8}>
          <div
            style={{
              width: '100%',
              marginTop: '80px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-evenly',
              marginLeft: '43px'
            }}>
            <img
              alt={product.prod_name}
              src={
                product.product_image_url
                  ? product.product_image_url
                  : productPDP
              }
              style={{ width: '323.008px', height: '332px' }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft:29
              }}>
              <div
                style={{
                  fontFamily: 'PlusJakartaSans-SemiBold',
                  fontSize: '16px',
                  color: brandNameColor,
                  marginTop: '4px',
                  textTransform:"capitalize"
                }}>
                {product.brand ? product.brand.name.toLowerCase() : 'Soul Sattva'} 
              </div>
              <div
                style={{
                  fontFamily: 'PlusJakartaSans-Bold',
                  fontSize: '30px',
                  textTransform: "lowercase",
                }}>
                {product.prod_name}
              </div>
              <div
                style={{
                  fontFamily: 'PlusJakartaSans-Bold',
                  fontSize: '25px',
                  marginTop: '27px'
                }}>
                &#8377; {product.price}.00
              </div>
              <div
                style={{
                  fontFamily: 'PlusJakartaSans-Bold',
                  fontSize: '10px',
                  color: '#9098A3',
                  textTransform: "lowercase",
                }}>
                Tax included. Shipping calculated at checkout.
              </div>
              <div style={{ marginTop: '22.34px' }}>
                <Button
                  variant={'contained'}
                  label={'shop now'}
                  size={"lg"}
                  styles={{ width: '174.187px',fontSize:"10px",fontFamily: 'PlusJakartaSans-Medium' }}
                  onClick={() =>
                    window.open(product.links ? product.links[0].url : null)
                  }
                  backgroundColor={buttonColor}
                />
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex'
            }}>
            <div
              style={{
                alignItems: 'flex-start',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: '89.43px',
                width: '59%'
              }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}>
                <div
                  style={{
                    fontFamily: 'PlusJakartaSans-Bold',
                    fontSize: '18.928px'
                  }}>
                  it's a super match
                </div>
                <div
                  style={{
                    fontFamily: 'PlusJakartaSans-Medium',
                    fontSize: '12.8px',
                    color: '#9098A3',
                    marginTop: '9.96px'
                  }}>
                  what makes it{' '}
                  <span
                    style={{
                      fontFamily: 'PlusJakartaSans-Bold',
                      fontStyle: 'italic'
                    }}>
                    super
                  </span>{' '}
                  exactly?
                </div>
              </div>
              <img
                alt='Perfect Match'
                src={perfectMatch}
                style={{ width: '31px', height: '31px' }}
              />
            </div>
          </div>
          <div style={{ width: '80%', height: '50%', padding: '10%' }}>
            <PdpSlider attributes={product.key_benefits} />
          </div>
        </Grid>
      </Grid>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}>
        <SmudgLogoComponent
          variant={'dark'}
          onClick={() => console.log('click')}
          styles={{
            width: '118px',
            height: '14.7px',
            position: "absolute",
            bottom: 13,
          }}
        />
      </div>
    </Box>
  );
}
