import React from "react";
import PropTypes from "prop-types";

export default function TextHighlighter({ highlightIndex, value }) {
  var textArr = value.split("");

  if (highlightIndex > textArr.length - 1) {
    highlightIndex = textArr.length - 1;
  }

  if (highlightIndex < 0) {
    highlightIndex = 0;
  }

  textArr[highlightIndex] = (
    <span key={highlightIndex} style={{ color:"#000"}}>{textArr[highlightIndex]}</span>
  );
  return <div style={styles}>{textArr}</div>;
}

let styles = {
    color: "rgba(0, 0, 0, 0.12)",
    fontFamily: "PlusJakartaSans-Bold",
    fontSize: "24px",
    fontWeight: "700"
};

TextHighlighter.defaultProps = {
  highlightIndex: 0,
  value: "osvp",
};

TextHighlighter.propTypes = {
  highlightIndex: PropTypes.number,
  value: PropTypes.string,
};
