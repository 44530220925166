import React, { useState } from "react";
import ImageCarousel from "../image-carousel/ImageCarousel";
import TextCarousel from "../text-carousel/TextCarousel";
import TextHighlighter from "../text-highlighter/TextHighlighter";
import PropTypes from "prop-types";

export default function BaumannBucketCarousel({
  baumannBucket,
  showImageCarousel,
  baumannBucketSummary,
}) {
  const [index, setIndex] = useState(0);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {showImageCarousel && (
        <div style={{ marginBottom: 26 }}>
          <ImageCarousel
            currentIndex={index}
            onChangeIndex={(i) => setIndex(i)}
          />
        </div>
      )}
      <div
        style={{
          color: "#A6944F",
          textAlign: "center",
          fontFamily: "PlusJakartaSans-Medium",
          fontSize: "11px",
          fontWeight: "500",
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        Here's a quick overview to help you understand each skin type!
      </div>
      <div style={{ marginTop: 4 }}>
        <TextHighlighter value={baumannBucket} highlightIndex={index} />
      </div>
      <div style={{ marginTop: 7, width: 520 }}>
        <TextCarousel
          textData={baumannBucketSummary}
          currentIndex={index}
          onChangeIndex={(i) => setIndex(i)}
        />
      </div>
    </div>
  );
}

BaumannBucketCarousel.defaultProps = {
  baumannBucket: "OSPF",
  showImageCarousel: false,
  baumannBucketSummary: [
    "'O' Oily skin excels in sebum production, offering natural defense against sun, pollution, and aging. Embrace it with products that balance hydration while controlling excess oiliness.",
    "'O' Oily skin excels in sebum production, offering natural defense against sun, pollution, and aging. Embrace it with products that balance hydration while controlling excess oiliness.",
    "'O' Oily skin excels in sebum production, offering natural defense against sun, pollution, and aging. Embrace it with products that balance hydration while controlling excess oiliness.",
    "'O' Oily skin excels in sebum production, offering natural defense against sun, pollution, and aging. Embrace it with products that balance hydration while controlling excess oiliness.",
  ],
};

BaumannBucketCarousel.propTypes = {
  baumannBucket: PropTypes.string,
  showImageCarousel: PropTypes.bool,
  baumannBucketSummary: PropTypes.array,
};
