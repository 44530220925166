import * as React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function CircularProgressWithLabel(props) {
  const [color, setColor] = React.useState();
  const [color1,setColor1]=React.useState()
  React.useEffect(() => {
    if (props.value < 40) {
      setColor('#B290F0');
    }
    if (props.value < 80 && props.value >= 40) {
      setColor('#B290F0');
    }
    if (props.value <= 100 && props.value >= 80) {
      setColor('#B290F0');
    }
  }, [props.value]);

  React.useEffect(() => {
    if (props.value <=25) {
      setColor1('#E57373');
    }
    if (props.value <=50 && props.value >25) {
      setColor1('#BA68C8');
    }
    if (props.value <= 75 && props.value >50) {
      setColor1('#81C784');
    }
    if (props.value <= 100 && props.value >75) {
      setColor1('#FFD54F');
    }
  }, [props.value]);

  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        sx={{
          color:color1
        }}
        size={104}
        thickness={1}
        variant="determinate"
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress
          size={83}
          thickness={4}
          sx={{color:color
          }}
          variant="determinate"
          {...props}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="caption" component="div" fontSize={14} fontFamily={"PlusJakartaSans-Bold"} color="text.primary">
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

export default function CircularWithValueLabel() {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 80 ? 80 : prevProgress + 10
      );
    }, 80);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return <CircularProgressWithLabel value={progress} />;
}
