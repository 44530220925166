import React, { useEffect } from "react";
import CloseButton from "../../../components/close-button/CloseButton";
import BrandLogo from "../../../components/brand-logo/BrandLogo";
import Button from "../../../components/button/Button";
import SmudgLogoComponent from "../../../components/smudg-logo/SmudgLogo";
import { useNavigate } from "react-router";
import images from "../../../assets";
import { onboardingImage, soulSattvaLogo } from "../../../assets/index";
import { OnboardingPageBrandLogo, buttonColor } from "../Config";
import PrivacyPolicy from "../../../assets/pdf/PRIVACY_POLICY_B2B.pdf";
import TAndC from "../../../assets/pdf/TERMS_B2B.pdf";

export default function Onboarding() {
  const navigate = useNavigate();

  const preloadImage = (src) =>
    new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = img.onabort = () => reject(src);
      img.src = src;
    });

  const handlePrivacy = () => {
    window.open(PrivacyPolicy);
  };

  const handleTAndC = () => {
    window.open(TAndC);
  };

  useEffect(() => {
    (async () => {
      const imagesPromiseList = [];
      for (const i in images) {
        imagesPromiseList.push(preloadImage(images[i]));
      }
      await Promise.all(imagesPromiseList);
    })();
  });

  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div style={{ width: "50%", height: "100%" }}>
          <img
            src={onboardingImage}
            alt="Onboarding"
            style={{ width: "100%", height: "100%" }}
          />
        </div>
        <div style={{ width: "50%", height: "100%" }}>
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                marginTop: "17px",
                marginRight: "16px",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end",
              }}
            >
              <CloseButton />
            </div>
            <div
              style={{
                marginTop: "78px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <BrandLogo
                image={OnboardingPageBrandLogo}
                onClick={() => console.log("click")}
              />
            </div>
            <div
              style={{
                marginTop: "28px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "80%",
                  fontSize: "19px",
                  fontWeight: "700",
                  fontFamily: "PlusJakartaSans-Bold",
                  textAlign: "center",
                  color: "#000",
                }}
              >
                SOUL SATTVA's skin analysis helps you find the right products
                for your skin.
              </div>
            </div>
            <div
              style={{
                marginTop: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "75%",
                  fontSize: "14px",
                  fontWeight: "600",
                  fontFamily: "PlusJakartaSans-SemiBold",
                  textAlign: "center",
                  color: "#000",
                }}
              >
                by tapping 'start skin analysis' you agree to our{" "}
                <span
                  style={{ textDecoration: "underline",cursor:"pointer" }}
                  onClick={() => handlePrivacy()}
                >
                  policy
                </span>{" "}
                and{" "}
                <span
                  style={{ textDecoration: "underline",cursor:"pointer" }}
                  onClick={() => handleTAndC()}
                >
                  t&c
                </span>
              </div>
            </div>
            <div
              style={{
                marginTop: "26px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                variant={"contained"}
                theme={"dark"}
                label={"start skin analysis"}
                size={"sm"}
                onClick={() => navigate("/first")}
                backgroundColor={buttonColor}
                styles={{
                  fontSize: 14,
                  fontFamily: "PlusJakartaSans-Bold",
                  width: "163px",
                  height: "41px",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <SmudgLogoComponent
                styles={{
                  width: "119px",
                  height: "14.7px",
                  position: "absolute",
                  bottom: 50,
                }}
                variant={"dark"}
                onClick={() => console.log("click")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
