import React from 'react';
import PropTypes from 'prop-types';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CircleButton from '../circle-button/CircleButton';
import McqOptionGrid from '../mcq-option-grid/McqOptionGrid';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function McqQuestion({
  multiSelect,
  children,
  options,
  onNextButtonClicked,
  showNextButton,
  showPrevButton,
  onPrevButtonClicked,
  chosenOptions,
  selectedOptions,
  spacing,
  buttonColor,
  styles
}) {
  
  return (
    <div style={{ width: '100%',...styles }}>
      {children}
      <McqOptionGrid
        onSelected={selectedOptions}
        styles={{
          marginTop: '28px',
          marginBottom: '19px'
        }}
        column={3}
        spacing={spacing}
        options={options}
        chosenOptions={chosenOptions}
        multiSelect={multiSelect} />
        <div>
        { showPrevButton &&
      <CircleButton
        icon={<ArrowBackIcon sx={{ color: '#fff',fontSize:18 }} />}
        size={'sm'}
        onClick={onPrevButtonClicked}
        styles={{marginRight:"15.376px"}}
        backgroundColor={buttonColor}
      />
        }
      {showNextButton &&
        <CircleButton
        icon={<ArrowForwardIcon sx={{ color: '#fff',fontSize:18 }} />}
        size={'sm'}
        onClick={onNextButtonClicked}
        backgroundColor={buttonColor}
      />}
      </div>
    </div>
  );
}

McqQuestion.propTypes = {
  multiSelect: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.element),
  showNextButton: PropTypes.bool,
  showPrevButton: PropTypes.bool,
  onNextButtonClicked: PropTypes.func,
  onPrevButtonClicked: PropTypes.func,
  children: PropTypes.element,
  choosedOptions:PropTypes.array,
  spacing:PropTypes.any,
  buttonColor:PropTypes.string,
  styles:PropTypes.object
};

McqQuestion.defaultProps = {
  multiSelect: false,
  options: [],
  showNextButton:true,
  showPrevButton:true,
  onNextButtonClicked: (value) => console.log(value),
  onPrevButtonClicked: (value) => console.log(value),
  children: <></>,
  spacing:18.43,
  choosedOptions:[],
  buttonColor:"#000",
  styles:{}
};
