import { soulSattvaLogo } from "../../assets";

export const buttonColor = "#6A6A6A";

export const borderColor = "#6A6A6A";

export const backgroundColor = "#6A6A6A";

export const brandNameColor = "#7B7B7B";

export const skinTypeOily = require('./icons/oily.png');
export const skinTypeDry = require('./icons/dry.png');
export const skinTypeCombination = require('./icons/combination.png');

export const subSkinTypeSensitive = require('./icons/sensitive.png');
export const subSkinTypeResistant = require('./icons/resistant.png');

export const subSkinTypePigmented = require('./icons/pigmented.png');
export const subSkinTypeEvenTone = require('./icons/even-tone.png');

export const subSkinTypeFirm = require('./icons/firm.png');
export const subSkinTypeWrinkleProne = require('./icons/wrinkle-prone.png');

export const OnboardingPageBrandLogo = (
  <img
    alt="Soul Sattva"
    src={soulSattvaLogo}
    style={{ height: "80px", width: "135px" }}
  />
);

export const BrandLogoImage = (
    <img
  alt='Soul Sattva'
  src={soulSattvaLogo}
  style={{
    height: '57px',
    width: '96px'
  }}
/>
);
