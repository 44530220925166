import * as React from 'react';
import PropTypes from 'prop-types';

export default function TabStepper({ tabs, activeTab }) {
  const tabLayout = () => {
    return tabs.map((item, index) => {
      return (
        <div
          style={{
            ...tabStyle.commonStyle,
            background:
              activeTab === item.name
                ? 'linear-gradient(180deg, #FFFFFF 97%, #000000 3%)'
                : null
          }}
          key={index}>
          <img
            alt={item.name}
            src={item.imageSrc}
            style={{
              width: 23,
              height: 23,
              filter: activeTab === item.name ? null : 'grayscale(100%)',
              opacity: activeTab === item.name ? 1 : 0.5
            }}
          />
          <div
            style={{
              marginTop: 9,
              color: activeTab === item.name ? 'black' : 'gray',
              fontSize:9,
              fontFamily:"PlusJakartaSans-Bold"
            }}>
            {item.name}
          </div>
          <div
            style={{
              marginTop: 3.95,
              color: activeTab === item.name ? 'black' : 'gray',
              fontSize:9,
              fontFamily:"PlusJakartaSans-Medium"
            }}>
            {item.text}
          </div>
        </div>
      );
    });
  };

  return (
    <div style={{ flexDirection: 'row', display: 'flex' }}>{tabLayout()}</div>
  );
}

TabStepper.defaultProps = {
  tabs: [
    {
      name: 'face',
      imageSrc: require('../../assets/quiz_icons/sparkle.png'),
      text: `1 of 6`
    },
    {
      name: 'eyes',
      imageSrc: require('../../assets/quiz_icons/eye.png'),
      text: '1 of 3'
    },
    {
      name: 'lips',
      imageSrc: require('../../assets/quiz_icons/lips.png'),
      text: '1 of 2'
    }
  ],
  activeTab: 'face'
};

TabStepper.propTypes = {
  tabs: PropTypes.array,
  activeTab: PropTypes.string
};

let tabStyle = {
  commonStyle: {
    width: 79,
    height: 60,
    display: 'flex',
    paddingBottom: 13,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderBottom: '1px solid black',
    fontFamily: 'PlusJakartaSans-Regular'
  }
};
