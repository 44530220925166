import React, { useEffect, useState } from "react";
import Button from "../button/Button";
import PropTypes from "prop-types";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CircleButton from "../circle-button/CircleButton";

export default function CircleMcqQuestion({
  headQuestion,
  image,
  subQuestion,
  questionArray,
  onSelected,
  onBackButtonClick,
  onNextButtonClick,
  value,
  buttonColor,
  borderColor
}) {
  const [op, setOp] = useState();

  const handleClick = (item) => {
    setOp(item);
    onSelected(item.value);
  };

  useEffect(() => {
    questionArray.forEach((item) => {
      if (item.value === value) {
        setOp(item);
        onSelected(item.value);
      }
    });
  }, [value, questionArray]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {headQuestion !== "" ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "80%",
              fontSize: "16px",
              fontWeight: "600",
              fontFamily: "PlusJakartaSans-SemiBold",
              textAlign: "center",
              textTransform:"lowercase"
            }}
          >
            {headQuestion}
          </div>
        </div>
      ) : null}
      {image != null ? (
        <div
          style={{
            marginTop:12.44,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {image}
        </div>
      ) : null}
      {subQuestion != null ? (
        <div
          style={{
            marginTop: 18.14,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "75%",
              fontSize: "12px",
              fontWeight: "500",
              fontFamily: "PlusJakartaSans-Medium",
              textAlign: "center",
              textTransform:"lowercase"
            }}
          >
            {subQuestion}
          </div>
        </div>
      ) : null}
      <div
        style={{
          marginTop: 25,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexDirection: "row",
            gap: 29,
          }}
        >
          {questionArray.map((item, index) => {
            if (item.id === op?.id) {
              return (
                <Button
                  key={index}
                  variant={"contained"}
                  theme={"dark"}
                  label={item.name}
                  backgroundColor={buttonColor}
                  size={"lg"}
                  onClick={() => handleClick(item)}
                  styles={{
                    width: "220px",
                    fontSize: "10px",
                    fontFamily: "PlusJakartaSans-Medium",
                  }}
                />
              );
            } else {
              return (
                <Button
                  key={index}
                  variant={"outlined"}
                  theme={"dark"}
                  label={item.name}
                  size={"lg"}
                  borderColor={borderColor}
                  onClick={() => handleClick(item)}
                  styles={{
                    width: "220px",
                    fontSize: "10px",
                    fontFamily: "PlusJakartaSans-Medium",
                  }}
                />
              );
            }
          })}
        </div>
      </div>
      <div
        style={{
          marginTop: 26,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15.376px",
            justifyContent: "space-between",
          }}
        >
          <CircleButton
            icon={<ArrowBackIcon sx={{ color: "#fff",fontSize:18 }} />}
            size={"sm"}
            onClick={() => onBackButtonClick()}
            backgroundColor={buttonColor}
          />
          <CircleButton
            icon={<ArrowForwardIcon sx={{ color: "#fff",fontSize:18 }} />}
            size={"sm"}
            onClick={() => onNextButtonClick()}
            backgroundColor={buttonColor}
          />
        </div>
      </div>
    </div>
  );
}

CircleMcqQuestion.propTypes = {
  headQuestion: PropTypes.string,
  image: PropTypes.element,
  subQuestion: PropTypes.string,
  questionArray: PropTypes.arrayOf(PropTypes.object),
  onSelected: PropTypes.func,
  onBackButtonClick: PropTypes.func,
  onNextButtonClick: PropTypes.func,
  value: PropTypes.string,
  buttonColor:PropTypes.string,
  borderColor:PropTypes.string
};

CircleMcqQuestion.defaultProps = {
  headQuestion: "",
  image: null,
  subQuestion: "",
  questionArray: [
    { id: 1, name: "sometimes,but it fades quickly", value: "non-pigmented" },
    { id: 2, name: "sometimes", value: "non-pigmented" },
    { id: 3, name: "yes, it lasts forever", value: "pigmented" },
  ],
  onSelected: (value) => console.log(value),
  onBackButtonClick: (value) => console.log(value),
  onNextButtonClick: (value) => console.log(value),
  value: "",
  buttonColor:"#000",
  borderColor:"#000"
};
