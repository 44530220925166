import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Onboarding from './screens/soul-sattva/Onboarding/OnboardingPage';
import FirstQuizQuestion from './screens/soul-sattva/FirstQuizQuestion/FirstQuizQuestion';
import SecondQuizQuestion from './screens/soul-sattva/SecondQuizQuestion/SecondQuizQuestion';
import ThirdQuizQuestion from './screens/soul-sattva/ThirdQuizQuestion/ThirdQuizQuestion';
import FirstFaceQuestion from './screens/soul-sattva/QuizQuestions/Face/FirstFaceQuestion';
import SecondFaceQuestion from './screens/soul-sattva/QuizQuestions/Face/SecondFaceQuestion';
import ThirdFaceQuestion from './screens/soul-sattva/QuizQuestions/Face/ThirdFaceQuestion';
import FourthFaceQuestion from './screens/soul-sattva/QuizQuestions/Face/FourthFaceQuestion';
import FifthFaceQuestion from './screens/soul-sattva/QuizQuestions/Face/FifthFaceQuestion';
import SixthFaceQuestion from './screens/soul-sattva/QuizQuestions/Face/SixthFaceQuestion';
import FirstEyesQuestion from './screens/soul-sattva/QuizQuestions/Eyes/FirstEyesQuestion';
import SecondEyesQuestion from './screens/soul-sattva/QuizQuestions/Eyes/SecondEyesQuestion';
import ThirdEyesQuestion from './screens/soul-sattva/QuizQuestions/Eyes/ThirdEyesQuestion';
import FirstLipsQuestion from './screens/soul-sattva/QuizQuestions/Lips/FirstLipsQuestion';
import SecondLipsQuestion from './screens/soul-sattva/QuizQuestions/Lips/SecondLipsQuestion';
import QuizResultsSummary from './screens/soul-sattva/QuizResultsSummary/QuizResultsSummary';
import ProdDetails from './screens/soul-sattva/ProdDetails/ProdDetails';
import ProductRecco from './screens/soul-sattva/ProductRecco/ProductRecco';
import PreQuizResults from './screens/soul-sattva/PreQuizResults/PreQuizResults';
import SkinSummary from './screens/soul-sattva/SkinSummary/SkinSummary';

function App() {
  return (
    <Router basename='/soul-sattva'>
      <Routes>
        <Route path='/'>
          <Route element={<Onboarding />} index />
          <Route element={<FirstQuizQuestion />} path='/first' />
          <Route element={<SecondQuizQuestion />} path='/second' />
          <Route element={<ThirdQuizQuestion />} path='/third' />
          <Route element={<FirstFaceQuestion />} path='/face-one' />
          <Route element={<SecondFaceQuestion />} path='/face-two' />
          <Route element={<ThirdFaceQuestion />} path='/face-three' />
          <Route element={<FourthFaceQuestion />} path='/face-four' />
          <Route element={<FifthFaceQuestion />} path='/face-five' />
          <Route element={<SixthFaceQuestion />} path='/face-six' />
          <Route element={<FirstEyesQuestion />} path='/eyes-one' />
          <Route element={<SecondEyesQuestion />} path='/eyes-two' />
          <Route element={<ThirdEyesQuestion />} path='/eyes-three' />
          <Route element={<FirstLipsQuestion />} path='/lips-one' />
          <Route element={<SecondLipsQuestion />} path='/lips-two' />
          <Route element={<PreQuizResults />} path='/pre-quiz-results' />
          <Route element={<SkinSummary />} path='/skin-summary' />
          <Route
            element={<QuizResultsSummary />}
            path='/quiz-results-summary'
          />
          <Route element={<ProdDetails />} path='/prod-details' />
          <Route element={<ProductRecco />} path='/product-recco' />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
