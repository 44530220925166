import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './McqOptionGrid.css';

export default function McqOptionGrid({
  spacing,
  column,
  styles,
  options,
  onSelected,
  multiSelect,
  maxAllowedSelections,
  chosenOptions
}) {
  const style = {
    height: '250px',
    ...styles,
    display: 'grid',
    gap: spacing,
    gridTemplateColumns: `repeat(${column},0fr)`,
    justifyContent: 'flexStart',
    maxWidth: '100%',
    overflow: 'hidden',
    overflowY: 'auto',
    paddingRight: 10
  };

  const [selectedIndex, setSelectedIndex] = useState([]);

  useEffect(() => {
    onSelected(selectedIndex);
  }, [onSelected, selectedIndex]);

  useEffect(() => {
    setSelectedIndex(chosenOptions);
  }, [chosenOptions]);

  return (
    <div style={style} className='mcqGrid'>
        {options.map((option, key) =>
          React.cloneElement(option, {
            onSelected: (value) => {
              if (multiSelect) {
                if (selectedIndex.includes(option.props.value)) {
                  // Item removal
                  setSelectedIndex(
                    selectedIndex.filter((item) => item !== option.props.value)
                  );
                } else {
                  // Item addition
                  if (maxAllowedSelections <= selectedIndex.length) {
                    return false;
                  }
                  setSelectedIndex([...selectedIndex, option.props.value]);
                }
              } else {
                setSelectedIndex([option.props.value]);
              }
            },
            value: option.props.value,
            key: key,
            isSelected: selectedIndex.includes(option.props.value)
          })
        )}
      </div>
  );
}

McqOptionGrid.propTypes = {
  spacing: PropTypes.number,
  column: PropTypes.number,
  styles: PropTypes.object,
  options: PropTypes.arrayOf(PropTypes.object),
  onSelected: PropTypes.func,
  multiSelect: PropTypes.bool,
  maxAllowedSelections: PropTypes.number,
  chosenOptions: PropTypes.array
};

McqOptionGrid.defaultProps = {
  options: [],
  onSelected: () => {},
  spacing: 2,
  column: 2,
  styles: {},
  multiSelect: false,
  maxAllowedSelections: 2,
  chosenOptions: []
};
