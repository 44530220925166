import React,{useEffect, useState} from "react";
import TextAnswer from "../text-answer/TextAnswer";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CircleButton from "../circle-button/CircleButton";
import PropTypes from "prop-types";
import TextFieldAnswer from "../textField-answer-component/TextFieldAnswer";

export default function TextQuestionComponent({
  children,
  styles,
  onNextButtonClicked,
  value,
  buttonColor,
  buttonStyles,
  textField,
}) {

 const [text,setText]=useState("")

 React.useEffect(() => {
 setText(value)
}, [value]);

  return (
    <div style={{ ...style.parent, ...styles }}>
      {children}
      <div style={{ marginTop: "2.5rem" }}>
      {
        textField ?
        <TextFieldAnswer value={text} onBlur={(val)=>setText(val)}/>
      :
        <TextAnswer value={text} onBlur={(val)=>setText(val)}/>
      }
      </div>
      <div
        style={{
          marginTop: "2rem",
          ...buttonStyles
        }}
      >
        <CircleButton
          icon={<ArrowForwardIcon sx={{ color: "#fff" }} />}
          size={"sm"}
          onClick={() => onNextButtonClicked(text)}
          backgroundColor={buttonColor}
        />
      </div>
    </div>
  );
}

let style = {
  parent: {
    display: "flex",
    flexDirection: "column",
  },
};

TextQuestionComponent.defaultProps = {
  children: (
    <div
      style={{
        color: "rgba(0, 0, 0, 1)",
        fontFamily: "PlusJakartaSans-Regular",
        fontSize: 18,
        fontStyle: "normal",
        fontWeight: "300",
      }}
    >
      What is your skin type?
    </div>
  ),
  styles: {},
  onNextButtonClicked: (value) => console.log(value),
  value:"",
  buttonColor:"#000",
  buttonStyles:{},
  textField:false
};

TextQuestionComponent.propTypes = {
  children:PropTypes.element,
  styles:PropTypes.object,
  buttonStyles:PropTypes.object,
  onNextButtonClicked:PropTypes.func,
  value:PropTypes.string,
  buttonColor:PropTypes.string,
  textField:PropTypes.bool
};
