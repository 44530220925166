import React from "react";
import CloseButton from "../../../components/close-button/CloseButton";
import BrandLogo from "../../../components/brand-logo/BrandLogo";
import SmudgLogoComponent from "../../../components/smudg-logo/SmudgLogo";
import { preQuizResultsGIF } from "../../../assets";
import Button from "../../../components/button/Button";
import { LinearGradient } from "react-text-gradients";
import { BrandLogoImage, buttonColor } from "../Config";
import { useNavigate } from "react-router";

export default function PreQuizResults() {
  const navigate = useNavigate();

  return (
    <div>
      <div style={{ width: "100%" }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            marginTop: "15px",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "flex-end",
              justifyContent: "center",
            }}
          >
            <BrandLogo image={BrandLogoImage} />
          </div>
          <div
            style={{
              position: "absolute",
              right: 27,
            }}
          >
            <CloseButton />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 35,
          }}
        >
          <div
            style={{
              fontSize: 28,
              fontFamily: "PlusJakartaSans-SemiBold",
              fontWeight: "600",
              textTransform: "lowercase",
              color:"#000"
            }}
          >
            
              you did great
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 10,
            marginBottom: 9,
          }}
        >
          <img
            alt="Congratulations"
            src={preQuizResultsGIF}
            style={{
              height: "126px",
              width: "220px",
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "435px",
              color: "#212121",
              fontSize: 18,
              fontFamily: "PlusJakartaSans-Medium",
              fontWeight: "500",
              textTransform: "lowercase",
              letterSpacing: 0.18,
              wordWrap: "break-word",
              textAlign: "center",
            }}
          >
            Thank you for your response
            <br />
            <br />
            On the next screen, discover your <i>true</i> skin type, referred to as
            `SkinSig` - a mix of your primary and secondary skin types, acting
            as your guide to finding the right products. 
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 27,
          }}
        >
          <Button
            variant={"contained"}
            theme={"dark"}
            label={"i’m ready"}
            backgroundColor={buttonColor}
            onClick={() => navigate("/skin-summary")}
            size={"sm"}
            styles={{
              width: "180px",
              fontSize: "14.03px",
              fontFamily: "PlusJakartaSans-Medium",
            }}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <SmudgLogoComponent
          variant={"dark"}
          onClick={() => console.log("click")}
          styles={{
            width: "118px",
            height: "14.7px",
            position: "absolute",
            bottom: 13,
          }}
        />
      </div>
    </div>
  );
}
