import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '../icon-button/IconButton';
import PropTypes from 'prop-types';

export default function CloseButton({ icon, onClick }) {
  function closeModal() {
    onClick();
    window.parent?.postMessage('closeModal', '*');
  }

  return <IconButton icon={icon} onClick={closeModal} />;
}

CloseButton.propTypes = {
  icon: PropTypes.element,
  onClick: PropTypes.func
};

CloseButton.defaultProps = {
  icon: (
    <CloseIcon
     style={{fontSize:23}}
    />
  ),
  onClick: () => {}
};
