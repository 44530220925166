import React, { useEffect, useState } from 'react';
import SmudgLogoComponent from '../../../components/smudg-logo/SmudgLogo';
import ProductCardCarousel from '../../../components/product-card-carousel/ProductCardCarousel';
import { useNavigate } from 'react-router';
import Skeleton from '@mui/material/Skeleton';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { selectBaumannBucket } from '../../../slices/QuizResultsReducer';
import { SERVER_URL } from '../../../Constants';
import Button from '../../../components/button/Button';
import CloseButton from '../../../components/close-button/CloseButton';
import BrandLogo from '../../../components/brand-logo/BrandLogo';
import { BrandLogoImage, borderColor, buttonColor } from '../Config';

export default function ProductRecco() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [productData, setProductData] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState();
  const [cardsPerSlide, setCardsPerSlide] = useState(4);
  const partnerSlug = 'soul_sattva';

  const baumann_bucket_selector = useSelector(selectBaumannBucket);
  const baumann_bucket = Object.values(baumann_bucket_selector)[0];

  const filtersArray = [
    {
      id: 1,
      text: 'cleanser',
      value: 'cleanser'
    },
    {
      id: 2,
      text: 'serum',
      value: 'serum'
    },
    {
      id: 3,
      text: 'gel',
      value: 'gel'
    },
    {
      id: 4,
      text: 'sun screen',
      value: 'sunscreen'
    },
    {
      id: 5,
      text: 'exfoliator',
      value: 'exfoliator'
    },
    {
      id: 6,
      text: 'eye cream',
      value: 'eye_cream'
    }
  ];

  const handleFilterSelection = (item) => {
    setSelectedFilter(item);
  };

  useEffect(() => {
    const getData = () => {
      setLoading(true);

      const options = {
        baumann_bucket: baumann_bucket,
        partner_slug: partnerSlug,
        product_category: selectedFilter ? selectedFilter.value : null
      };

      axios
        .post(SERVER_URL.RECOMMENDATIONS, options, {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
            // Authorization: "Bearer " + token,
          }
        })
        .then((res) => {
          const data = res.data.skincare_products;

          if (data.length === 0) setCardsPerSlide(0);
          else if (data.length === 1) setCardsPerSlide(1);
          else if (data.length < 4) setCardsPerSlide(parseInt(data.length / 2));

          setProductData(data);
          setLoading(false);
        })
        .catch((err) => {
          console.log('Error: ', err);
          setLoading(false);
        });
    };

    getData();
  }, [baumann_bucket, selectedFilter]);

  const filterTabs = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            flexDirection: 'row',
            gap: '19px'
          }}>
          {filtersArray.map((item, index) => {
            if (item.id === selectedFilter?.id) {
              return (
                <Button
                  key={index}
                  variant={'contained'}
                  theme={'dark'}
                  label={item.text}
                  backgroundColor={buttonColor}
                  size={'sm'}
                  onClick={() => handleFilterSelection(item)}
                  styles={{
                    width: '77px',
                    fontSize: '10px',
                    fontFamily: 'PlusJakartaSans-Medium',
                    height:"29px"
                  }}
                />
              );
            } else {
              return (
                <Button
                  key={index}
                  variant={'outlined'}
                  theme={'dark'}
                  label={item.text}
                  size={'sm'}
                  borderColor={borderColor}
                  onClick={() => handleFilterSelection(item)}
                  styles={{
                    width: '77px',
                    fontSize: '10px',
                    fontFamily: 'PlusJakartaSans-Medium',
                    height:"29px"
                  }}
                />
              );
            }
          })}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div style={{ width: '100%' }}>
        <div
          style={{
            marginTop: '15px',
            marginRight: '27px',
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end'
          }}>
          <CloseButton />
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            marginTop: '41px',
            alignItems: 'flex-end',
            justifyContent: 'space-between'
          }}>
          <div
            style={{
              display: 'flex',
              width: '100%',
              fontFamily: 'PlusJakartaSans-Bold',
              fontSize: '32px',
              paddingLeft: '6%',
              alignItems:"flex-end",
              justifyContent: 'center'
            }}>
            Your Top Product Matches
          </div>
          <BrandLogo styles={{marginRight: '27px'}} image={BrandLogoImage} />
        </div>
        <div
          style={{
            fontFamily: 'PlusJakartaSans-Medium',
            fontSize: '16px',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '8px',
            marginBottom: '47px'
          }}>
          these products will work the best for you according to your skin type!
        </div>
        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <div style={{ width: '80%' }}>
            {loading ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-evenly',
                  width: '100%',
                  alignItems: 'center'
                }}>
                <Skeleton
                  width={200}
                  height={350}
                  animation={'wave'}
                  variant='rectangular'
                  style={{
                    borderRadius: 15
                  }}
                />
                <Skeleton
                  width={200}
                  height={350}
                  animation={'wave'}
                  variant='rectangular'
                  style={{
                    borderRadius: 15
                  }}
                />
                <Skeleton
                  width={200}
                  height={350}
                  animation={'wave'}
                  variant='rectangular'
                  style={{
                    borderRadius: 15
                  }}
                />
                <Skeleton
                  width={200}
                  height={350}
                  animation={'wave'}
                  variant='rectangular'
                  style={{
                    borderRadius: 15
                  }}
                />
              </div>
            ) : (
              <div>
                <div>{filterTabs()}</div>
                {cardsPerSlide > 0 ? (
                  <div>
                    <ProductCardCarousel
                      onClick={(item) => {
                        navigate('/prod-details', { state: { item: item } });
                      }}
                      cardData={productData}
                      numberOfCardsPerSlide={cardsPerSlide}
                      buttonColor={buttonColor}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      alignContent: 'center',
                      justifyContent: 'center',
                      marginTop: 50
                    }}>
                    There are no recommendations for the selected filter.
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}>
        <SmudgLogoComponent
          variant={'dark'}
          onClick={() => console.log('click')}
          styles={{
            width: '118px',
            height: '14.7px',
            position: "absolute",
            bottom: 13,
          }}
        />
      </div>
    </div>
  );
}
