import React from "react";
import PropTypes from "prop-types";
import { Checkbox } from "@mui/material";

export default function ImageOption({
  value,
  label,
  image,
  isSelected,
  onSelected,
}) {
  const style = {
    width: "100%",
    height: "94.422px",
    borderRadius: 2,
  };

  const handleClick = () => {
    onSelected(!isSelected);
  };

  return (
    <div
      style={{
        display: "flex",
        width: "99.525px",
        height: "auto",
        alignItems: "center",
        position: "relative",
        flexDirection: "column",
        userSelect: "none",
      }}
    >
      <div style={style} onClick={() => handleClick()}>
        {image}
        <Checkbox
          checked={isSelected}
          onChange={() => handleClick()}
          sx={{
            color: "#000",
            "&.Mui-checked": {
              color: "#000",
            },
            position: "absolute",
            top: "5.69px",
            right: "6.23px",
            "& .MuiSvgIcon-root": { fontSize: "15.008px" },
            width: 0,
            height: 0,
          }}
        />
      </div>
      <div
        style={{
          fontSize: "8.726px",
          fontWeight: "700",
          color: "#5C5C5C",
          fontFamily: "PlusJakartaSans-Bold",
          marginTop: "9.31px",
        }}
      >
        {label}
      </div>
    </div>
  );
}

ImageOption.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  image: PropTypes.element,
  isSelected: PropTypes.bool,
  onSelected: PropTypes.func,
};

ImageOption.defaultProps = {
  value: "light",
  label: "Light",
  image: (
    <img
      alt="Face"
      src={require("../../assets/FaceImage.png")}
      style={{ width: "100%", height: "100%" }}
    />
  ),
  isSelected: false,
  onSelected: (value) => console.log(value),
};
