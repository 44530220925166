import React from "react";
import PropTypes from "prop-types";

export default function BrandLogo({ image, onClick,styles }) {
  return (
    <>
        {image == null ? (
          <div
        style={{
          width: "96px",
          height: "57px",
          backgroundColor: "#D9D9D9",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
          <div style={{ fontSize: "12px", color: "#000" }}>brand Logo</div>
          </div>
        ) : (
          <div style={styles}>{image}</div>
        )}
    </>
  );
}

BrandLogo.propTypes = {
  image: PropTypes.element,
  onClick: PropTypes.func,
  styles:PropTypes.object
};

BrandLogo.defaultProps = {
  image: null,
  onClick: () => console.log("click"),
  styles:{}
};
