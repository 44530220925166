import React, { useState } from "react";
import PropTypes from "prop-types";

export default function SmudgLogo({ variant, styles }) {

  const handleClick=()=>{
    window.open("https://www.smudg.co/")
  }

  const style={
    ...styles,
    minWidth:"118px",
    minHeight:"14.7px",
    cursor:"pointer"
  }
  return (
    <>
      {variant === "dark" ? (
        <img
          src={require("../../assets/smudg_logo_black.png")}
          onClick={() =>handleClick()}
          alt="Smudg Logo"
          style={style}
        />
      ) : (
        <img
          src={require("../../assets/smudg_logo_white.png")}
          onClick={() => handleClick()}
          alt="Smudg Logo"
          style={style}
        />
      )}
    </>
  );
}

SmudgLogo.propTypes = {
  variant: PropTypes.oneOf(["dark", "light"]),
  styles:PropTypes.object
};

SmudgLogo.defaultProps = {
  variant: "dark",
  styles:{}
};
