import React from 'react';
import PropTypes from 'prop-types';
import { Button as MuiButton} from '@mui/material';

export default function ButtonComponent({
  variant,
  size,
  label,
  icon,
  iconPos,
  showIcon,
  outline,
  onClick,
  backgroundColor,
  disabled,
  borderRadius,
  textColor,
  borderColor,
  theme,
  styles
}) {
  const style = {
    ...styles,
    minWidth: '50px',
    borderRadius: borderRadius,
    backgroundColor:
      backgroundColor === '' && theme === 'dark' && variant === 'contained'
        ? 'black'
        : backgroundColor === '' && theme === 'light' && variant === 'contained'
        ? 'white'
        : variant === 'contained'
        ? backgroundColor
        : '',
    borderColor: variant === 'outlined' ? borderColor : '',
    color:
      textColor === '' && theme === 'dark' && variant !== 'contained'
        ? 'black'
        : textColor === '' && theme === 'light' && variant !== 'contained'
        ? 'black'
        : textColor === '' && theme === 'dark'
        ? 'white'
        : textColor === '' && theme === 'light'
        ? 'black'
        : textColor,
    textTransform: 'none',
    borderWidth: variant === 'outlined' ? 1.5 : 0
  };
  return (
    <>
      {showIcon === true &&
      (icon !== null || icon !== undefined || icon !== '') ? (
        iconPos === 'left' ? (
          <MuiButton
            variant={variant}
            size={size === 'lg' ? 'large' : size === 'md' ? 'medium' : 'small'}
            disabled={disabled}
            startIcon={icon}
            style={style}
            onClick={() => onClick()}>
            {label}
          </MuiButton>
        ) : (
          <MuiButton
            variant={variant}
            size={size === 'lg' ? 'large' : size === 'md' ? 'medium' : 'small'}
            disabled={disabled}
            endIcon={icon}
            style={style}
            onClick={() => onClick()}>
            {label}
          </MuiButton>
        )
      ) : (
        <MuiButton
          variant={variant}
          size={size === 'lg' ? 'large' : size === 'md' ? 'medium' : 'small'}
          disabled={disabled}
          style={style}
          onClick={() => onClick()}>
          {label}
        </MuiButton>
      )}
    </>
  );
}

ButtonComponent.propTypes = {
  variant: PropTypes.oneOf(['text', 'contained', 'outlined']),
  theme: PropTypes.oneOf(['dark', 'light']),
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  label: PropTypes.string,
  icon: PropTypes.element,
  iconPos: PropTypes.oneOf(['left', 'right']),
  showIcon: PropTypes.bool,
  outline: PropTypes.bool,
  onClick: PropTypes.func,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  borderColor: PropTypes.string,
  disabled: PropTypes.bool,
  borderRadius: PropTypes.number,
  styles: PropTypes.object
};

ButtonComponent.defaultProps = {
  variant: 'contained',
  theme: 'dark',
  size: 'md',
  label: 'button',
  icon: (
    <img
      alt='Dryness'
      src={require(`../../assets/dryness.png`)}
      style={{ width: '32px', height: '33.6px' }}
    />
  ),
  iconPos: 'right',
  showIcon: false,
  outline: false,
  backgroundColor: '',
  disabled: false,
  borderRadius: 50,
  textColor: '',
  borderColor: '',
  onClick: () => console.log('click'),
  styles: {}
};
