import React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CircleButton from "../circle-button/CircleButton";
import PropTypes from "prop-types";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ProductCard from "../product-card/ProductCard";
import "./productCardCarousel.css";

export default function ProductCardCarousel({
  cardData,
  numberOfCardsPerSlide,
  onClick,
  buttonColor
}) {
  const sliderRef = React.useRef(null);

  const NextButton = () => {
    return (
      <CircleButton
        icon={<ArrowForwardIcon sx={{ color: "#fff", fontSize: 18 }} />}
        size={"sm"}
        onClick={() => sliderRef?.current?.slickNext()}
        backgroundColor={buttonColor}
      />
    );
  };

  const PrevButton = () => {
    return (
      <CircleButton
        icon={<ArrowBackIcon sx={{ color: "#fff", fontSize: 18 }} />}
        size={"sm"}
        onClick={() => sliderRef?.current?.slickPrev()}
        backgroundColor={buttonColor}
      />
    );
  };

  const makeProductCard = () => {
    return cardData.map((item, key) => {
      return (
        <ProductCard onClick={() => onClick(item)} data={item} key={key} />
      );
    });
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: numberOfCardsPerSlide,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: null,
    prevArrow: null,
    adaptiveHeight: true,
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <PrevButton></PrevButton>
      <Slider ref={sliderRef} style={{ width: 830 }} {...settings}>
        {makeProductCard()}
      </Slider>
      <NextButton></NextButton>
    </div>
  );
}

ProductCardCarousel.defaultProps = {
  cardData: [
    {
      brand: "soul sattva",
      prod_name: "Anti Blemish Cream -50 gms",
    },
    {
      brand: "soul sattva",
      prod_name: "Anti Blemish Cream -50 gms",
    },
    {
      brand: "soul sattva",
      prod_name: "Anti Blemish Cream -50 gms",
    },
    {
      brand: "soul sattva",
      prod_name: "Anti Blemish Cream -50 gms",
    },
    {
      brand: "soul sattva",
      prod_name: "Anti Blemish Cream -50 gms",
    },
    {
      brand: "soul sattva",
      prod_name: "Anti Blemish Cream -50 gms",
    },
    {
      brand: "soul sattva",
      prod_name: "Anti Blemish Cream -50 gms",
    },
  ],
  numberOfCardsPerSlide: 4,
  onClick: () => console.log("click"),
  buttonColor:""
};

ProductCardCarousel.propTypes = {
  cardData: PropTypes.array,
  numberOfCardsPerSlide: PropTypes.number,
  onClick: PropTypes.func,
  buttonColor:PropTypes.string
};
