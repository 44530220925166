import React, { useState } from "react";
import SmudgLogoComponent from "../../../components/smudg-logo/SmudgLogo";
import CloseButton from "../../../components/close-button/CloseButton";
import McqQuestion from "../../../components/mcq-question/McqQuestion";
import ImageOption from "../../../components/image-option/ImageOption";
import { useDispatch, useSelector } from "react-redux";
import { selectThird, setThird } from "../../../slices/QuizQuestionReducer";
import { useNavigate } from "react-router";
import {
  darkSkinTone,
  extremelyDarkSkinTone,
  lightMediumSkinTone,
  lightSkinTone,
  mediumDarkSkinTone,
  mediumSkinTone,
} from "../../../assets";
import { BrandLogoImage, buttonColor } from "../Config";
import BrandLogo from "../../../components/brand-logo/BrandLogo";

export default function ThirdQuizQuestion() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const option = useSelector(selectThird);
  const optionSelected = Object.values(option)[0];

  const [selectedOption, setSelectedOption] = useState([]);

  const question = () => {
    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{
            color: "rgba(15, 15, 14, 0.70)",
            fontFamily: "PlusJakartaSans-Bold",
            fontSize: 28,
            marginTop: -5,
            marginRight: 10,
          }}
        >
          Q3.
        </div>
        <div
          style={{
            fontSize: "16px",
            fontFamily: "PlusJakartaSans-Medium",
            textTransform: "lowercase",
          }}
        >
          What is your skin's melanin level? Select the tone below that best
          matches your skin.
        </div>
      </div>
    );
  };

  const mcqOptionValues = {
    light: {
      value: "light",
      label: "Light",
      image: (
        <img
          alt="Light"
          src={lightSkinTone}
          style={{ width: "100%", height: "100%" }}
        />
      ),
    },
    mediumLight: {
      value: "medium_light",
      label: "Medium Light",
      image: (
        <img
          alt="Medium Light"
          src={lightMediumSkinTone}
          style={{ width: "100%", height: "100%" }}
        />
      ),
    },
    medium: {
      value: "medium",
      label: "Medium",
      image: (
        <img
          alt="Medium"
          src={mediumSkinTone}
          style={{ width: "100%", height: "100%" }}
        />
      ),
    },
    mediumDeep: {
      value: "medium_deep",
      label: "Medium Deep",
      image: (
        <img
          alt="Medium Deep"
          src={mediumDarkSkinTone}
          style={{ width: "100%", height: "100%" }}
        />
      ),
    },
    deep: {
      value: "deep",
      label: "Deep",
      image: (
        <img
          alt="Deep"
          src={darkSkinTone}
          style={{ width: "100%", height: "100%" }}
        />
      ),
    },
    extremelyDark: {
      value: "extremely_dark",
      label: "Extremely Dark",
      image: (
        <img
          alt="Extremely Dark"
          src={extremelyDarkSkinTone}
          style={{ width: "100%", height: "100%" }}
        />
      ),
    },
  };

  const McqQuestionArgs = {
    multiSelect: false,
    options: [
      <ImageOption {...mcqOptionValues.light} />,
      <ImageOption {...mcqOptionValues.mediumLight} />,
      <ImageOption {...mcqOptionValues.medium} />,
      <ImageOption {...mcqOptionValues.mediumDeep} />,
      <ImageOption {...mcqOptionValues.deep} />,
      <ImageOption {...mcqOptionValues.extremelyDark} />,
    ],
    children: question(),
    onPrevButtonClicked: () => navigate("/second"),
    chosenOptions: optionSelected,
    spacing: 18.43,
  };

  const handleClick = () => {
    if (selectedOption.length !== 0) {
      dispatch(setThird(selectedOption));
      navigate("/face-one");
    }
  };

  return (
    <div>
      <div style={{ width: "100%" }}>
        <div
          style={{
            marginTop: "15px",
            marginRight: "27px",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <CloseButton />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            marginTop: "41px",
            alignItems: "flex-end",
          }}
        >
          <BrandLogo styles={{ marginRight: "27px" }} image={BrandLogoImage} />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "flex-start",
            marginBottom: "19px",
            justifyContent: "center",
            marginTop: 51,
          }}
        >
          <McqQuestion
            {...McqQuestionArgs}
            selectedOptions={setSelectedOption}
            onNextButtonClicked={() => handleClick()}
            buttonColor={buttonColor}
            styles={{
              width: "70%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <SmudgLogoComponent
          variant={"dark"}
          onClick={() => console.log("click")}
          styles={{
            width: "118px",
            height: "14.7px",
            position: "absolute",
            bottom: 13,
          }}
        />
      </div>
    </div>
  );
}
