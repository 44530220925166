import React, { useEffect, useState } from 'react';
import SmudgLogoComponent from '../../../components/smudg-logo/SmudgLogo';
import BrandLogo from '../../../components/brand-logo/BrandLogo';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Button from '../../../components/button/Button';
import Skeleton from '@mui/material/Skeleton';

import {
  selectl2,
  selectFirst,
  selectSecond,
  selectThird,
  selecte1,
  selecte2,
  selecte3,
  selectf1,
  selectf2,
  selectf3,
  selectf4,
  selectf5,
  selectf6,
  selectl1,
} from "../../../slices/QuizQuestionReducer";
import axios from "axios";
import { setBaumannBucket } from "../../../slices/QuizResultsReducer";
import { SERVER_URL } from "../../../Constants";
import {
  quizResultCombination,
  quizResultDry,
  quizResultOily,
} from "../../../assets";
import CloseButton from "../../../components/close-button/CloseButton";
import { BrandLogoImage, buttonColor } from '../Config';

export default function QuizResultsSummary() {
  const [loading, setLoading] = useState(true);
  const [parameters, setParameters] = useState({
    baumann_bucket: "",
    baumann_bucket_text: "",
  });
  const [skinTypeRes, setSkinTypeRes] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const q = useSelector(selectFirst);
  const q1 = Object.values(q)[0];
  const q2 = useSelector(selectSecond)
    .map((el) => el)
    .toString();
  const q3 = useSelector(selectThird)
    .map((el) => el)
    .toString();
  const f1 = useSelector(selectf1)
    .map((el) => el.f1)
    .toString();
  const f2 = useSelector(selectf2)
    .map((el) => el.f2)
    .toString();
  const f3 = useSelector(selectf3)
    .map((el) => el.f3)
    .toString();
  const f4 = useSelector(selectf4)
    .map((el) => el.f4)
    .toString();
  const f5 = useSelector(selectf5)
    .map((el) => el.f5)
    .toString();
  const f6 = useSelector(selectf6)
    .map((el) => el.f6)
    .toString();
  const l1 = useSelector(selectl1)
    .map((el) => el.l1)
    .toString();
  const l2 = useSelector(selectl2)
    .map((el) => el.l2)
    .toString();
  const e1 = useSelector(selecte1)
    .map((el) => el.e1)
    .toString();
  const e2 = useSelector(selecte2)
    .map((el) => el.e2)
    .toString();
  const e3 = useSelector(selecte3)
    .map((el) => el.e3)
    .toString();

  useEffect(() => {
    const options = {
      name: q1,
      gender: "NA",
      product_preference: "both",
      product_categories: "all",
      skincare_goals: q2,
      skin_tone: q3,
      oiliness_shine: f1,
      pores: f2,
      dryness: f3,
      sensitivity: f4,
      pigmented: f5,
      wrinkled: f6,
      face_shape: null,
      eye_concern: e1,
      eye_brow_type: e2,
      eye_lash_type: e3,
      eye_color: "black",
      eye_shape: null,
      lip_type: l1,
      lip_concern: l2,
      partner_slug: "soul_sattva",
    };

    const getData = () => {
      setLoading(true);

      console.log("SERVER_URL: ", SERVER_URL.REGISTER);

      axios
        .post(SERVER_URL.REGISTER, options, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            // Authorization: "Bearer " + token,
          },
        })
        .then((res) => {
          const data = res.data.skin_parameters;
          const bucket = data.baumann_bucket.toLowerCase();
          setParameters(data);
          setSkinTypeRes(bucket.charAt(0));
          setLoading(false);
          dispatch(setBaumannBucket(bucket));
        })
        .catch((err) => {
          console.log("Error: ", err);
          setLoading(false);
        });
    };

    getData();
  }, [dispatch, e1, e2, e3, f1, f2, f3, f4, f5, f6, l1, l2, q1, q2, q3]);

  return (
    <div>
      <div style={{ width: "100%" }}>
        <div
          style={{
            marginTop: "15px",
            marginRight: "27px",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <CloseButton />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            marginTop: "41px",
            alignItems: "flex-end",
          }}
        >
          <BrandLogo styles={{marginRight: '27px'}} image={BrandLogoImage} />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
            alignItems: "center",
          }}
        >
          {loading ? (
            <Skeleton
              variant="circular"
              width={"115.176px"}
              height={"115.176px"}
              animation="wave"
            />
          ) : (
            <div
              style={{
                width: "115.176px",
                height: "115.176px",
                borderRadius: "50%",
                filter: "drop-shadow(0px 12px 6px rgba(0, 0, 0, 0.25))",
                border: "2px solid black",
                backgroundColor: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                alt="skin type logo"
                src={
                  skinTypeRes === "o"
                    ? quizResultOily
                    : skinTypeRes === "d"
                    ? quizResultDry
                    : quizResultCombination
                }
                style={
                  skinTypeRes === "o"
                    ? { width: "37.072px", height: "53.549px" }
                    : { width: "57.6px", height: "57.6px" }
                }
              />
            </div>
          )}
          {loading ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignSelf: "center",
                alignItems: "center",
              }}
            >
              <Skeleton
                variant="text"
                style={{
                  marginTop: "27.82px",
                }}
                width={100}
                height={25}
              />
              <Skeleton
                variant="text"
                width={220}
                height={25}
                style={{
                  marginTop: "12.57px",
                }}
              />
              <Skeleton
                variant="text"
                width={160}
                height={20}
                style={{
                  marginTop: "6px",
                }}
              />
              <Skeleton
                variant="text"
                width={280}
                height={20}
                style={{
                  marginTop: "29px",
                }}
              />
              <Skeleton
                variant="rectagular"
                width={190}
                height={30}
                style={{
                  borderRadius: 50,
                  marginTop: "54.43px",
                }}
              />
            </div>
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignSelf: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  marginTop: "27.82px",
                  fontSize: "24px",
                  fontFamily: "PlusJakartaSans-Bold",
                  textTransform: "lowercase",
                }}
              >
                {parameters.baumann_bucket}
              </div>
              <div
                style={{
                  marginTop: "12.57px",
                  fontSize: "16px",
                  fontFamily: "PlusJakartaSans-SemiBold",
                  textTransform: "lowercase",
                }}
              >
                {parameters.baumann_bucket_text}
              </div>
              <div
                style={{
                  marginTop: "6px",
                  fontSize: "13px",
                  fontFamily: "PlusJakartaSans-Medium",
                  textTransform: "lowercase",
                }}
              >
                congratulations on identifying your true skin-type!
              </div>
              <div
                style={{
                  marginTop: "29px",
                  fontSize: "13px",
                  fontFamily: "PlusJakartaSans-Medium",
                  textTransform: "lowercase",
                }}
              >
                use this as a guide to find the best product matches
              </div>
              <div
                style={{
                  marginTop: "54.43px",
                }}
              >
                <Button
                  variant={"contained"}
                  theme={"dark"}
                  label={"my product matches"}
                  size={"lg"}
                  onClick={() => navigate("/product-recco")}
                  styles={{
                    width: "180px",
                    fontSize: "10px",
                    fontFamily: "PlusJakartaSans-Medium",
                  }}
                  backgroundColor={buttonColor}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <SmudgLogoComponent
          variant={"dark"}
          onClick={() => console.log("click")}
          styles={{
            width: '118px',
            height: '14.7px',
            position: "absolute",
            bottom: 13,
          }}
        />
      </div>
    </div>
  );
}
