import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SERVER_URL } from "../../../Constants";
import {
  selectl2,
  selectFirst,
  selectSecond,
  selectThird,
  selecte1,
  selecte2,
  selecte3,
  selectf1,
  selectf2,
  selectf3,
  selectf4,
  selectf5,
  selectf6,
  selectl1,
} from "../../../slices/QuizQuestionReducer";
import { setBaumannBucket } from "../../../slices/QuizResultsReducer";
import CloseButton from "../../../components/close-button/CloseButton";
import BrandLogo from "../../../components/brand-logo/BrandLogo";
import SmudgLogoComponent from "../../../components/smudg-logo/SmudgLogo";
import { skinSummary } from "../../../assets";
import Button from "../../../components/button/Button";
import BaumannBucketCarousel from "../../../components/baumann-bucket-carousel/BaumannBucketCarousel";
import {
  BrandLogoImage,
  buttonColor,
  skinTypeCombination,
  skinTypeDry,
  skinTypeOily,
  subSkinTypeEvenTone,
  subSkinTypeFirm,
  subSkinTypePigmented,
  subSkinTypeResistant,
  subSkinTypeSensitive,
  subSkinTypeWrinkleProne,
} from "../Config";
import Skeleton from "@mui/material/Skeleton";
import { skinSummaryImage } from "../../../assets/index";

export default function SkinSummary() {
  const [loading, setLoading] = useState(true);
  const [parameters, setParameters] = useState({
    baumann_bucket: "",
    baumann_bucket_text: "",
    baumann_bucket_summary: "",
  });
  const [skinTypeRes, setSkinTypeRes] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const q = useSelector(selectFirst);
  const q1 = Object.values(q)[0];
  const q2 = useSelector(selectSecond)
    .map((el) => el)
    .toString();
  const q3 = useSelector(selectThird)
    .map((el) => el)
    .toString();
  const f1 = useSelector(selectf1)
    .map((el) => el.f1)
    .toString();
  const f2 = useSelector(selectf2)
    .map((el) => el.f2)
    .toString();
  const f3 = useSelector(selectf3)
    .map((el) => el.f3)
    .toString();
  const f4 = useSelector(selectf4)
    .map((el) => el.f4)
    .toString();
  const f5 = useSelector(selectf5)
    .map((el) => el.f5)
    .toString();
  const f6 = useSelector(selectf6)
    .map((el) => el.f6)
    .toString();
  const l1 = useSelector(selectl1)
    .map((el) => el.l1)
    .toString();
  const l2 = useSelector(selectl2)
    .map((el) => el.l2)
    .toString();
  const e1 = useSelector(selecte1)
    .map((el) => el.e1)
    .toString();
  const e2 = useSelector(selecte2)
    .map((el) => el.e2)
    .toString();
  const e3 = useSelector(selecte3)
    .map((el) => el.e3)
    .toString();

  useEffect(() => {
    const options = {
      name: q1,
      gender: "NA",
      product_preference: "both",
      product_categories: "all",
      skincare_goals: q2,
      skin_tone: q3,
      oiliness_shine: f1,
      pores: f2,
      dryness: f3,
      sensitivity: f4,
      pigmented: f5,
      wrinkled: f6,
      face_shape: null,
      eye_concern: e1,
      eye_brow_type: e2,
      eye_lash_type: e3,
      eye_color: "black",
      eye_shape: null,
      lip_type: l1,
      lip_concern: l2,
      partner_slug: "soul_sattva",
    };

    const getData = () => {
      setLoading(true);

      axios
        .post(SERVER_URL.REGISTER, options, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            // Authorization: "Bearer " + token,
          },
        })
        .then((res) => {
          const data = res.data.skin_parameters;
          const bucket = data.baumann_bucket.toLowerCase();
          setParameters(data);
          setSkinTypeRes(bucket.charAt(0));
          setLoading(false);
          dispatch(setBaumannBucket(bucket));
        })
        .catch((err) => {
          console.log("Error: ", err);
          setLoading(false);
        });
    };

    getData();
  }, [dispatch, e1, e2, e3, f1, f2, f3, f4, f5, f6, l1, l2, q1, q2, q3]);

  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div style={{ width: "40%", height: "100%" }}>
          <img
            src={skinSummaryImage}
            alt="SkinSummary"
            style={{ width: "100%", height: "100%" }}
          />
        </div>
        <div style={{ width: "60%", height: "100%" }}>
          <div style={{ width: "100%" }}>
            <div
              style={{
                position: "absolute",
                top: 15,
                right: 27,
              }}
            >
              <CloseButton />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 45,
                alignItems: "center",
                flexDirection: "column",
                gap: 15,
              }}
            >
              <BrandLogo image={BrandLogoImage} />
              <div
                style={{
                  fontSize: 30,
                  fontFamily: "PlusJakartaSans-Bold",
                  fontWeight: "700",
                }}
              >
                {loading ? (
                  <Skeleton
                    variant="text"
                    width={"415.176px"}
                    height={"59.176px"}
                    animation="wave"
                  />
                ) : (
                  `Your Unique SkinSig is : ${parameters.baumann_bucket.toLowerCase()}`
                )}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 12,
                marginTop: 27,
                flexDirection: "row",
                gap: 25,
              }}
            >
              {loading ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <Skeleton
                      variant="circular"
                      width={48}
                      height={48}
                      animation="wave"
                    />
                    <Skeleton
                      variant="text"
                      width={100}
                      height={25}
                      animation="wave"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <Skeleton
                      variant="circular"
                      width={48}
                      height={48}
                      animation="wave"
                    />
                    <Skeleton
                      variant="text"
                      width={100}
                      height={25}
                      animation="wave"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <Skeleton
                      variant="circular"
                      width={48}
                      height={48}
                      animation="wave"
                    />
                    <Skeleton
                      variant="text"
                      width={100}
                      height={25}
                      animation="wave"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <Skeleton
                      variant="circular"
                      width={48}
                      height={48}
                      animation="wave"
                    />
                    <Skeleton
                      variant="text"
                      width={100}
                      height={25}
                      animation="wave"
                    />
                  </div>
                </>
              ) : (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <img
                      src={
                        parameters.skin_type === "oily"
                          ? skinTypeOily
                          : parameters.skin_type === "dry"
                          ? skinTypeDry
                          : skinTypeCombination
                      }
                      alt="skinType"
                      style={{ width: 48, height: 48 }}
                    />
                    {parameters.skin_type === "oily"
                      ? "Oily (O)"
                      : parameters.skin_type === "dry"
                      ? "Dry (D)"
                      : "Combination (C)"}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <img
                      src={
                        parameters.sensitive
                          ? subSkinTypeSensitive
                          : subSkinTypeResistant
                      }
                      alt="skinType"
                      style={{ width: 48, height: 48 }}
                    />
                    {parameters.sensitive ? "Sensitive (S)" : "Resistant (R)"}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <img
                      src={
                        parameters.pigmentation
                          ? subSkinTypePigmented
                          : subSkinTypeEvenTone
                      }
                      alt="skinType"
                      style={{ width: 48, height: 48 }}
                    />
                    {parameters.pigmentation
                      ? "Pigmented (P)"
                      : "Even-Tone (E)"}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <img
                      src={
                        parameters.wrinkles
                          ? subSkinTypeWrinkleProne
                          : subSkinTypeFirm
                      }
                      alt="skinType"
                      style={{ width: 48, height: 48 }}
                    />
                    {parameters.wrinkles ? "Wrinkle-Prone (W)" : "Firm (F)"}
                  </div>
                </>
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 12,
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {loading ? (
                <>
                  <Skeleton
                    variant="text"
                    width={"300px"}
                    height={"23px"}
                    animation="wave"
                  />
                  <Skeleton
                    variant="text"
                    width={"100px"}
                    height={"43px"}
                    animation="wave"
                  />
                  <Skeleton
                    variant="rectangular"
                    width={"453px"}
                    height={"153px"}
                    animation="wave"
                  />
                </>
              ) : (
                <BaumannBucketCarousel
                  showImageCarousel={false}
                  baumannBucket={parameters.baumann_bucket.toLowerCase()}
                  baumannBucketSummary={parameters.baumann_bucket_summary}
                />
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 19,
              }}
            >
              <Button
                variant={"contained"}
                theme={"dark"}
                label={"show my matches"}
                backgroundColor={buttonColor}
                size={"lg"}
                disabled={loading}
                styles={{
                  width: "180px",
                  fontSize: "10px",
                  fontFamily: "PlusJakartaSans-Medium",
                }}
                onClick={() => navigate("/product-recco")}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <SmudgLogoComponent
              variant={"dark"}
              onClick={() => console.log("click")}
              styles={{
                width: "118px",
                height: "14.7px",
                position: "absolute",
                bottom: 13,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
