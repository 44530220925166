import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";

export default function TextAnswer({
  multiLine,
  value,
  placeholder,
  onBlur,
}) {
  const [text, setText] = React.useState(value);

  React.useEffect(() => {
    setText(value);
  }, [value])

  return (
    <Box
      sx={{
        width: 500,
        maxWidth: "100%",
      }}
    >
      <TextField
        id="standard-basic"
        placeholder={placeholder}
        variant="standard"
        multiline={multiLine}
        sx={{
          "& .MuiInput-underline:after": {
            borderBottom: "2px solid rgba(15, 15, 14, 0.70)",
          },
        }}
        style={{
          ...style.theme,
        }}
        value={text}
        onChange={(event) => {
          setText(event.target.value);
          onBlur(event.target.value)
        }}
        fullWidth
      />
    </Box>
  );
}

let style = {
  theme: {
    color: "#6F7E8C",
  },
};

TextAnswer.defaultProps = {
  multiLine: false,
  value: "",
  placeholder: "Type your answer here...",
  onBlur: (val) => {
    console.log("Value: ", val);
  },
};

TextAnswer.propTypes={
  multiLine:PropTypes.bool,
  value:PropTypes.string,
  placeholder:PropTypes.string,
  onBlur:PropTypes.func,
}
