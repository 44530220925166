import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  baumann_bucket:"",
};

export const quizResultsSlice = createSlice({
  name: "quizResults",
  initialState,
  reducers: {
    setBaumannBucket: (state, action) => {
      state.baumann_bucket = [action.payload];
    },
  },
});

export const {
  setBaumannBucket,
} = quizResultsSlice.actions;

export const selectBaumannBucket = (state) => state.quizResults.baumann_bucket;

export default quizResultsSlice.reducer;
