import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  card: {
    width: "132.585px",
    minHeight: "237.508px",
    maxHeight: "320px",
    height: "auto",
    borderWidth: 1,
    borderRadius: 10,
    marginTop: "28px",
    paddingBottom: "8.63px",
    backgroundColor: "#FBFBFB",
    position: "relative",
  },

  upperCard: {
    height: "127px",
    borderBottomWidth: 1,
    backgroundColor: "#E9E9E9",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },
  match: {
    position: "absolute",
    borderRadius: 50,
    top: -9,
    backgroundColor: "#000",
    width: "79.169px",
    height: "19.077px",
    zIndex: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  prodNameStyle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginLeft: 9,
    marginRight: 5,
  },
  brandName: {
    marginTop: 10,
    fontFamily: "PlusJakartaSans-Bold",
    fontSize: "10px",
    color: "black",
  },
  prodName: {
    marginTop: 5,
    marginLeft: 8,
    marginRight: 8,
    fontFamily: "PlusJakartaSans-Bold",
    fontSize: "10px",
    color: "#6B6C6C",
    height: "26px",
  },
  prodCategory: {
    fontSize: "9px",
    fontFamily: "PlusJakartaSans-Medium",
    color: "#6B6C6C",
    fontWeight: "500",
    marginLeft: 8,
    marginRight: 8,
    height: "10px",
  },
  buttonDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginLeft: 8,
    marginTop: 21,
  },
  button: {
    display: "flex",
    width: "47.692px",
    height: "25.754px",
    borderRadius: 50,
    border: "1px solid black",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  buttonText: {
    color: "black",
    fontFamily: "PlusJakartaSans-Regular",
    fontSize: "9px",
    textTransform: "lowercase",
    fontWeight: "400",
    cursor: "pointer",
  },
});

export { useStyles };
