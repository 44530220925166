import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ImageCarousel.css"

export default function ImageCarousel({ImageData,currentIndex,onChangeIndex}) {
    let sliderRef = React.useRef(null);
    const [slideIndex, setSlideIndex] = useState(0);

  const makeImageCard = () => {
    return ImageData.map((item, key) => {
      return <div>{item}</div>;
    });
  };

  useEffect(()=>{
    setSlideIndex(currentIndex)
    sliderRef.slickGoTo(currentIndex)
  },[currentIndex])


  const handleChange=(index)=>{
    setSlideIndex(index)
    onChangeIndex(index)
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    focusOnSelect:false,
    beforeChange: (current, next) => handleChange(next)
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Slider ref={slider => {
          sliderRef = slider;
        }} style={{ width: 300 }} {...settings}>
        {makeImageCard()}
      </Slider>
    </div>
  );
}

ImageCarousel.defaultProps = {
  ImageData: [
    <img
      src={require("../../assets/SkinSummaryImage.png")}
      style={{ width: "293px", height: "193px" }}
      alt="image"
    />,
    <img
      src={require("../../assets/SkinSummaryImage.png")}
      style={{ width: "293px", height: "193px" }}
      alt="image"
    />,
    <img
      src={require("../../assets/SkinSummaryImage.png")}
      style={{ width: "293px", height: "193px" }}
      alt="image"
    />,
    <img
      src={require("../../assets/SkinSummaryImage.png")}
      style={{ width: "293px", height: "193px" }}
      alt="image"
    />
  ],
};

ImageCarousel.propTypes = {
  ImageData: PropTypes.array,
};
