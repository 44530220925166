import React from 'react';
import SmudgLogoComponent from '../../../../components/smudg-logo/SmudgLogo';
import BrandLogo from '../../../../components/brand-logo/BrandLogo';
import TabStepper from '../../../../components/tab-stepper/TabStepper';
import CircleMcqQuestion from '../../../../components/circle-mcq-question/CircleMcqQuestion';
import { useDispatch, useSelector } from 'react-redux';
import { selecte3, sete3 } from '../../../../slices/QuizQuestionReducer';
import { useNavigate } from 'react-router';
import {
  eyeIcon,
  eyesQuizQ3,
  lipsIcon,
  sparkleIcon
} from '../../../../assets';
import CloseButton from '../../../../components/close-button/CloseButton';
import { BrandLogoImage, borderColor, buttonColor } from '../../Config';

export default function ThirdEyesQuestion() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selected, setSelected] = React.useState("");

  const questionProps = {
    headQuestion: "let's talk lashes!",
    value: useSelector(selecte3)
      .map((el) => el.e3)
      .toString(),
    image: (
      <img
        alt="Eyes Quiz Question 3"
        src={eyesQuizQ3}
        style={{ width: "109px", height: "109px", borderRadius: "50%" }}
      />
    ),
    subQuestion: "which of the following describes your lashes the best?",
    questionArray: [
      {
        id: 1,
        name: "short and sweet",
        value: "scanty,short",
      },
      {
        id: 2,
        name: "medium and mesmerising",
        value: "long,thick",
      },
      {
        id: 3,
        name: "long and luxurious",
        value: "normal",
      },
    ],
    onSelected: (item) => {
      setSelected(item);
    },
  };

  const tabProperties = {
    tabs: [
      {
        name: "face",
        imageSrc: sparkleIcon,
        text: "6 of 6",
      },
      {
        name: "eyes",
        imageSrc: eyeIcon,
        text: "3 of 3",
      },
      {
        name: "lips",
        imageSrc: lipsIcon,
        text: "1 of 2",
      },
    ],
    activeTab: "eyes",
  };

  const handleBackClick = () => {
    navigate("/eyes-two");
  };

  const handleNextClick = () => {
    if (selected !== "") {
      dispatch(sete3({ e3: selected }));
      navigate("/lips-one");
    }
  };

  return (
    <div>
      <div style={{ width: "100%" }}>
        <div
          style={{
            marginTop: "15px",
            marginRight: "27px",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <CloseButton />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            marginTop: "41px",
            justifyContent: "space-evenly",
          }}
        >
          <div
            style={{
              width: "100%",
              alignItems: "flex-start",
              justifyContent: "start",
              fontSize: 20,
              fontFamily: "PlusJakartaSans-Bold",
            }}
          >
            <div style={{ marginLeft: "57px" }}>Eyes Profile</div>
          </div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <TabStepper {...tabProperties} />
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
            }}
          >
           <BrandLogo styles={{marginRight: '27px'}} image={BrandLogoImage} />
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "flex-start",
            marginTop: "21.69px",
          }}
        >
          <CircleMcqQuestion
            {...questionProps}
            onBackButtonClick={() => handleBackClick()}
            onNextButtonClick={() => handleNextClick()}
            buttonColor={buttonColor}
            borderColor={borderColor}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <SmudgLogoComponent
          variant={"dark"}
          onClick={() => console.log("click")}
          styles={{
            width: "119px",
            height: "14.7px",
            position: "absolute",
            bottom: 13,
          }}
        />
      </div>
    </div>
  );
}
