import React from 'react';
import SmudgLogoComponent from '../../../../components/smudg-logo/SmudgLogo';
import TabStepper from '../../../../components/tab-stepper/TabStepper';
import CircleMcqQuestion from '../../../../components/circle-mcq-question/CircleMcqQuestion';
import { useDispatch, useSelector } from 'react-redux';
import { selectf2, setf2 } from '../../../../slices/QuizQuestionReducer';
import { useNavigate } from 'react-router';
import {
  eyeIcon,
  faceQuizQ2,
  lipsIcon,
  sparkleIcon
} from '../../../../assets';
import CloseButton from '../../../../components/close-button/CloseButton';
import BrandLogo from '../../../../components/brand-logo/BrandLogo';
import { BrandLogoImage, borderColor, buttonColor } from '../../Config';

export default function SecondFaceQuestion() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selected, setSelected] = React.useState("");

  const questionProps = {
    headQuestion: "let's talk pores",
    value: useSelector(selectf2)
      .map((el) => el.f2)
      .toString(),
    image: (
      <img
        alt="Face Quiz Question 2"
        src={faceQuizQ2}
        style={{ width: "109px", height: "109px", borderRadius: "50%" }}
      />
    ),
    subQuestion:
      "do you notice pores all over your face, in your t-zone, or none at all?",
    questionArray: [
      {
        id: 1,
        name: "yes, my pores are super visible",
        value: "oily",
      },
      {
        id: 2,
        name: "yes, but mainly in my t-zone",
        value: "combi",
      },
      {
        id: 3,
        name: "no, my pores aren't a concern",
        value: "dry",
      },
    ],
    onSelected: (item) => {
      setSelected(item);
    },
  };

  const tabProperties = {
    tabs: [
      {
        name: "face",
        imageSrc: sparkleIcon,
        text: "2 of 6",
      },
      {
        name: "eyes",
        imageSrc: eyeIcon,
        text: "1 of 3",
      },
      {
        name: "lips",
        imageSrc: lipsIcon,
        text: "1 of 2",
      },
    ],
    activeTab: "face",
  };

  const handleBackClick = () => {
    navigate("/face-one");
  };

  const handleNextClick = () => {
    if (selected !== "") {
      dispatch(setf2({ f2: selected }));
      navigate("/face-three");
    }
  };

  return (
    <div>
    <div style={{ width: "100%" }}>
      <div
        style={{
          marginTop: "15px",
          marginRight: "27px",
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        <CloseButton />
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          marginTop: "41px",
          justifyContent: "space-evenly",
        }}
      >
        <div
          style={{
            width: "20%",
            alignItems: "flex-start",
            justifyContent: "start",
            fontSize: 20,
            fontFamily: "PlusJakartaSans-Bold",
          }}
        >
        <div style={{marginLeft:"57px"}}>
          Face Profile
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            width: '60%',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <TabStepper {...tabProperties} />
        </div>
        <div
          style={{
            display: "flex",
            width: "20%",
            justifyContent: "flex-end",
          }}
        >
         <BrandLogo styles={{marginRight: '27px'}} image={BrandLogoImage} />
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "flex-start",
          marginTop: "21.69px",
        }}
      >
        <CircleMcqQuestion
          {...questionProps}
          onBackButtonClick={() => handleBackClick()}
          onNextButtonClick={() => handleNextClick()}
          buttonColor={buttonColor}
          borderColor={borderColor}
        />
      </div>
    </div>
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <SmudgLogoComponent
        variant={"dark"}
        onClick={() => console.log("click")}
        styles={{
          width: "119px",
          height: "14.7px",
          position: "absolute",
          bottom: 13,
        }}
      />
    </div>
  </div>
  );
}
