import React, { useState } from "react";
import SmudgLogoComponent from "../../../components/smudg-logo/SmudgLogo";
import McqQuestion from "../../../components/mcq-question/McqQuestion";
import McqOption from "../../../components/mcq-option/McqOption";
import { useDispatch, useSelector } from "react-redux";
import { setSecond, selectSecond } from "../../../slices/QuizQuestionReducer";
import { useNavigate } from "react-router";
import {
  chappedLips,
  dryness,
  dullness,
  linesWrinkles,
  oilShine,
  pores,
  acne,
  darkCircles,
  darkSpots,
} from "../../../assets";
import CloseButton from "../../../components/close-button/CloseButton";
import { BrandLogoImage, buttonColor } from "../Config";
import BrandLogo from "../../../components/brand-logo/BrandLogo";

export default function SecondQuizQuestion() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const option = useSelector(selectSecond);
  const optionSelected = Object.values(option)[0];

  const [selectedOptions, setSelectedOptions] = useState([]);

  const question = () => {
    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{
            color: "rgba(15, 15, 14, 0.70)",
            fontFamily: "PlusJakartaSans-Bold",
            fontSize: 28,
            marginTop: -5,
            marginRight: 10,
          }}
        >
          Q2.
        </div>
        <div
          style={{
            fontSize: "16px",
            fontFamily: "PlusJakartaSans-Medium",
            textTransform: "lowercase",
          }}
        >
          Nice to meet you! Lets start with your skin concerns. To begin, let's
          focus on your skin concerns. Select 2 primary concerns.
        </div>
      </div>
    );
  };

  const mcqOptionValues = {
    dryness: {
      value: "dryness",
      label: "Dryness",
      image: (
        <img
          alt="Dryness"
          src={dryness}
          style={{ width: "32px", height: "33.6px" }}
        />
      ),
    },
    pores: {
      value: "pores",
      label: "Pores",
      image: (
        <img
          alt="Pores"
          src={pores}
          style={{ width: "32px", height: "33.6px" }}
        />
      ),
    },
    dullness: {
      value: "dullness",
      label: "Dullness",
      image: (
        <img
          alt="Dullness"
          src={dullness}
          style={{ width: "32px", height: "33.6px" }}
        />
      ),
    },
    linesAndWrinkles: {
      value: "lines_wrinkles",
      label: "Lines & wrinkles",
      image: (
        <img
          alt="Lines & Wrinkles"
          src={linesWrinkles}
          style={{ width: "32px", height: "33.6px" }}
        />
      ),
    },
    chappedLips: {
      value: "chapped_lips",
      label: "Chapped Lips",
      image: (
        <img
          alt="Chapped Lips"
          src={chappedLips}
          style={{ width: "32px", height: "33.6px" }}
        />
      ),
    },
    oilAndShine: {
      value: "oil_shine",
      label: "Oil & Shine",
      image: (
        <img
          alt="Oil & Shine"
          src={oilShine}
          style={{ width: "15.0237px", height: "22.097px" }}
        />
      ),
    },
    dark_spots: {
      value: "darkspots",
      label: "Dark Spots",
      image: (
        <img
          alt="Dark Spots"
          src={darkSpots}
          style={{ width: "32px", height: "33.6px" }}
        />
      ),
    },
    dark_circles: {
      value: "dark_circles",
      label: "dark circles",
      image: (
        <img
          alt="Dark Circles"
          src={darkCircles}
          style={{ width: "32px", height: "33.6px" }}
        />
      ),
    },
    acne_and_breakouts: {
      value: "acne_and_breakouts",
      label: "Acne & Breakouts",
      image: (
        <img
          alt="Acne & Breakouts"
          src={acne}
          style={{ width: "32px", height: "33.6px" }}
        />
      ),
    },
  };

  const McqQuestionArgs = {
    multiSelect: true,
    options: [
      <McqOption {...mcqOptionValues.dryness} />,
      <McqOption {...mcqOptionValues.pores} />,
      <McqOption {...mcqOptionValues.dullness} />,
      <McqOption {...mcqOptionValues.linesAndWrinkles} />,
      <McqOption {...mcqOptionValues.chappedLips} />,
      <McqOption {...mcqOptionValues.oilAndShine} />,
      <McqOption {...mcqOptionValues.acne_and_breakouts} />,
      <McqOption {...mcqOptionValues.dark_circles} />,
      <McqOption {...mcqOptionValues.dark_spots} />,
    ],
    children: question(),
    onPrevButtonClicked: () => navigate("/first"),
    chosenOptions: optionSelected,
    spacing: 24.99,
  };

  const handleClick = () => {
    if (selectedOptions.length !== 0) {
      dispatch(setSecond(selectedOptions));
      navigate("/third");
    }
  };

  return (
    <div>
      <div style={{ width: "100%" }}>
        <div
          style={{
            marginTop: "15px",
            marginRight: "27px",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <CloseButton />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            marginTop: "41px",
            alignItems: "flex-end",
          }}
        >
          <BrandLogo styles={{ marginRight: "27px" }} image={BrandLogoImage} />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "flex-start",
            marginBottom: "19px",
            justifyContent: "center",
            marginTop: 51,
          }}
        >
          <McqQuestion
            {...McqQuestionArgs}
            selectedOptions={setSelectedOptions}
            onNextButtonClicked={() => handleClick()}
            buttonColor={buttonColor}
            styles={{
              width: "70%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <SmudgLogoComponent
          variant={"dark"}
          onClick={() => console.log("click")}
          styles={{
            width: "118px",
            height: "14.7px",
            position: "absolute",
            bottom: 13,
          }}
        />
      </div>
    </div>
  );
}
