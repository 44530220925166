import React from "react";
import PropTypes from "prop-types";
import { Fab } from "@mui/material";

export default function CircleButton({
  variant,
  theme,
  size,
  icon,
  outline,
  onClick,
  styles,
  backgroundColor
}) {

  const style = {
    ...styles,
    borderRadius: 50,
    backgroundColor:
    backgroundColor === "" && theme === "dark" && variant === "contained"
    ? "black"
    : backgroundColor === "" && theme === "light" && variant === "contained"
    ? "white"
    : variant === "contained"
    ? backgroundColor
    : "",
  };

  return (
    <>
          <Fab
            variant={variant}
            size={size === "lg" ? "large" : size === "md" ? "medium" : "small"}
            style={style}
            onClick={()=>onClick()}
          >
          {icon}
          </Fab>
    </>
  );
}

CircleButton.propTypes = {
  variant: PropTypes.oneOf(["text", "contained", "outlined"]),
  theme: PropTypes.oneOf(["dark", "light"]),
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  icon: PropTypes.element,
  outline: PropTypes.bool,
  onClick: PropTypes.func,
  styles:PropTypes.object,
  backgroundColor: PropTypes.string,
};

CircleButton.defaultProps = {
  variant: "contained",
  theme: "dark",
  size: "md",
  icon: (
    <img
      alt='Dryness'
      src={require(`../../assets/dryness.png`)}
      style={{ width: '32px', height: '33.6px' }}
    />
  ),
  outline: false,
  onClick:()=>console.log("click"),
  styles:{},
  backgroundColor:""
};
