import React, { useState } from "react";
import SmudgLogoComponent from "../../../../components/smudg-logo/SmudgLogo";
import TabStepper from "../../../../components/tab-stepper/TabStepper";
import CircleMcqQuestion from "../../../../components/circle-mcq-question/CircleMcqQuestion";
import { useDispatch, useSelector } from "react-redux";
import { setf1, selectf1 } from "../../../../slices/QuizQuestionReducer";
import { useNavigate } from "react-router";
import { eyeIcon, faceQuizQ1, lipsIcon, sparkleIcon } from "../../../../assets";
import CloseButton from "../../../../components/close-button/CloseButton";
import BrandLogo from "../../../../components/brand-logo/BrandLogo";
import { BrandLogoImage, borderColor, buttonColor } from "../../Config";

export default function FirstFaceQuestion() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selected, setSelected] = useState("");

  const questionProps = {
    headQuestion: "your skin's oil balance",
    value: useSelector(selectf1)
      .map((el) => el.f1)
      .toString(),
    image: (
      <img
        alt="Face Quiz Question 1"
        src={faceQuizQ1}
        style={{ width: "109px", height: "109px", borderRadius: "50%" }}
      />
    ),
    subQuestion: "does your skin tend to get oily or shiny throughout the day?",
    questionArray: [
      {
        id: 1,
        name: "yes, skin is oily 24/7",
        value: "oily",
      },
      {
        id: 2,
        name: "yes, but mainly in my t-zone",
        value: "combi",
      },
      {
        id: 3,
        name: "no, my skin always feels dry",
        value: "dry",
      },
    ],
    onSelected: (item) => {
      setSelected(item);
    },
  };

  const tabProperties = {
    tabs: [
      {
        name: "face",
        imageSrc: sparkleIcon,
        text: "1 of 6",
      },
      {
        name: "eyes",
        imageSrc: eyeIcon,
        text: "1 of 3",
      },
      {
        name: "lips",
        imageSrc: lipsIcon,
        text: "1 of 2",
      },
    ],
    activeTab: "face",
  };

  const handleBackClick = () => {
    navigate("/third");
  };

  const handleNextClick = () => {
    if (selected !== "") {
      dispatch(setf1({ f1: selected }));
      navigate("/face-two");
    }
  };

  return (
    <div>
    <div style={{ width: "100%" }}>
      <div
        style={{
          marginTop: "15px",
          marginRight: "27px",
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        <CloseButton />
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          marginTop: "41px",
          justifyContent: "space-evenly",
        }}
      >
        <div
          style={{
            width: "20%",
            alignItems: "flex-start",
            justifyContent: "start",
            fontSize: 20,
            fontFamily: "PlusJakartaSans-Bold",
          }}
        >
        <div style={{marginLeft:"57px"}}>
          Face Profile
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            width: '60%',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <TabStepper {...tabProperties}/>
        </div>
        <div
          style={{
            display: "flex",
            width: "20%",
            justifyContent: "flex-end",
          }}
        >
          <BrandLogo styles={{marginRight: '27px'}} image={BrandLogoImage} />
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "flex-start",
          marginTop: "21.69px",
        }}
      >
        <CircleMcqQuestion
          {...questionProps}
          onBackButtonClick={() => handleBackClick()}
          onNextButtonClick={() => handleNextClick()}
          buttonColor={buttonColor}
          borderColor={borderColor}
        />
      </div>
    </div>
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <SmudgLogoComponent
        variant={"dark"}
        onClick={() => console.log("click")}
        styles={{
          width: "119px",
          height: "14.7px",
          position: "absolute",
          bottom: 13,
        }}
      />
    </div>
  </div>
  );
}
