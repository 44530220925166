import React from 'react';
import PropTypes from 'prop-types';

export default function IconButton({ icon, onClick }) {
  return (
    <div
      style={{ cursor: 'pointer' }}
      onClick={() => onClick()}
      role='button'
      tabIndex={0}>
      {icon}
    </div>
  );
}

IconButton.propTypes = {
  icon: PropTypes.element,
  onClick: PropTypes.func
};

IconButton.defaultProps = {
  icon: null,
  onClick: () => console.log('click')
};
